<div class="loading-main-content" *ngIf="!showEmptyResult">
    <div *ngIf="showSpinner">
        <ion-spinner name="crescent" style="margin-top: 15px"></ion-spinner>
    </div>
    <div *ngIf="!showSpinner">
        <ion-button *ngIf="activateBackButton && showBackButton" (click)="backToHome()">
            {{backToHomeButtonText | translate}}
        </ion-button>
        <ion-button *ngIf="showActionButton" (click)="actionButtonClick()">{{actionButtonText | translate}}</ion-button>
    </div>
</div>
<div class="loading-main-content" *ngIf="showEmptyResult">
    <div>{{emptyResultText}}</div>
</div>

import mergician from 'mergician';
import { Inject, Injectable, Optional } from '@angular/core';
import { AppConfigBaseClass } from '../entities';
import { CurafidaEnvironmentConfig } from '../../common/entities/curafida-environment-config.type';
import { AppTemplateConfig } from '../entities/app-template-config';
import { BuildType } from '../entities/build-type';
import packageJson from '../../../package.json';

@Injectable({
    providedIn: 'root',
})
export class ConfigService {
    constructor(@Optional() @Inject('environmentConfig') private environmentConfig?: CurafidaEnvironmentConfig) {}

    private _config: AppConfigBaseClass;

    get config(): AppConfigBaseClass {
        return this._config;
    }

    set config(configBaseClass: AppConfigBaseClass) {
        this._config = configBaseClass;
    }

    set configFromTemplate(configFromProject: AppConfigBaseClass) {
        const mergeOptions = {};
        this._config = mergician(mergeOptions)({}, new AppTemplateConfig(), configFromProject, this.environmentConfig);
        if (this._config.developmentStage !== BuildType.prod) {
            console.debug('Merged frontend configuration:');
            console.debug(this._config);
        }
    }

    initFromJson() {
        const mergeOptions = {};
        this._config = mergician(mergeOptions)({}, new AppTemplateConfig(), this.environmentConfig);
        this._config.appVersion =  packageJson.version;
    }
}

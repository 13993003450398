import { Component, EventEmitter, Input, OnInit, Output, Type, ViewChild } from '@angular/core';
import { ActionType, ItemAdapterComponent, ItemType, TableItem } from '../../entities/table';
import { PopoverController } from '@ionic/angular';
import { CurafidaPopoverSelectionComponent } from '../curafida-popover-selection/curafida-popover-selection.component';
import { ItemAdapterHostDirective } from './item-adapter-host.directive';

@Component({
    selector: 'curafida-column-definition',
    templateUrl: './curafida-column-definition.component.html',
    styleUrls: ['./curafida-column-definition.component.scss'],
})
export class CurafidaColumnDefinitionComponent<T> implements OnInit {
    @Input()
    mobileAdapter: Type<ItemAdapterComponent>;
    @Input()
    itemSetting: TableItem;
    @Input()
    row: T;
    @Input()
    isMobile: boolean;
    @Input()
    rowIndex: number;
    @Output()
    updateList = new EventEmitter<boolean>();
    @Output()
    actionSelected = new EventEmitter<ActionType>();
    @ViewChild(ItemAdapterHostDirective, { static: true }) itemAdapterHost!: ItemAdapterHostDirective;
    ItemType = ItemType;

    constructor(private popoverController: PopoverController) {
    }

    @Input() private _isReorderDisabled: boolean;

    set isReorderDisabled(value: boolean) {
        this._isReorderDisabled = value;
        this.loadComponent();
    }

    ngOnInit() {
        if (this.isMobile) {
            this.loadMobileComponent();
        } else if (this.itemSetting.type === ItemType.ADAPTER) this.loadComponent();
    }

    loadComponent() {
        if (this.itemAdapterHost) {
            const viewContainerRef = this.itemAdapterHost.viewContainerRef;
            viewContainerRef.clear();

            const componentRef = viewContainerRef.createComponent<ItemAdapterComponent>(this.itemSetting.adapter);
            componentRef.instance.data = this.row;
            componentRef.instance.itemSetting = this.itemSetting;
            componentRef.instance.rowIndex = this.rowIndex;
            componentRef.instance.isReorderDisable = this._isReorderDisabled;
            if (componentRef.instance.updateList)
                componentRef.instance.updateList.subscribe((value) => this.updateList.emit(value));

            if (componentRef.instance.actionSelected) {
                componentRef.instance.actionSelected.subscribe((value) => {
                    if (this.itemSetting.actionType === ActionType.POPOVER) {
                        this.openActionSelection(value.mouseEvent);
                    } else if (this.itemSetting.actionType === ActionType.OPEN_NEW_PAGE) {
                        this.actionSelected.emit(value.action);
                    }
                });
            }
        }
    }

    loadMobileComponent() {
        if (this.itemAdapterHost) {
            const viewContainerRef = this.itemAdapterHost.viewContainerRef;
            viewContainerRef.clear();
            const componentRef = viewContainerRef.createComponent<ItemAdapterComponent>(this.mobileAdapter);
            componentRef.instance.data = this.row;
            this.itemSetting = new TableItem();
            this.itemSetting.type = ItemType.ADAPTER;
            componentRef.instance.itemSetting = this.itemSetting;
            componentRef.instance.rowIndex = this.rowIndex;
            componentRef.instance.isReorderDisable = this._isReorderDisabled;
            if (componentRef.instance.updateList)
                componentRef.instance.updateList.subscribe((value) => this.updateList.emit(value));
            if (componentRef.instance.actionSelected) {
                componentRef.instance.actionSelected.subscribe((value) => {
                    if (this.itemSetting.actionType === ActionType.POPOVER) {
                        this.openActionSelection(value.mouseEvent);
                    } else if (this.itemSetting.actionType === ActionType.OPEN_NEW_PAGE) {
                        this.actionSelected.emit(value.action);
                    } else {
                        this.actionSelected.emit(value.action);
                    }
                });
            }
        }
    }

    async openActionSelection(ev?) {
        const popover = await this.popoverController.create({
            component: CurafidaPopoverSelectionComponent,
            cssClass: 'my-custom-class',
            event: ev,
            translucent: true,
            componentProps: {
                // @ts-ignore
                actionItems: this.row?.actions,
            },
        });
        await popover.present();
        const { data } = await popover.onDidDismiss();
        if (data) this.actionSelected.emit(data);
    }
}

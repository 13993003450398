<table class='table full-width' *ngIf='!_listTableConfig.hasMobileConfig || !isMobile'>
    <thead>
    <tr [hidden]='_listTableConfig?.hideHeader' class='flex-container-row full-width'>
        <th (click)='changeSortOrder(col)'
            *ngFor='let col of _listTableConfig?.itemSettings| filterWeb'
            [hidden]='!col.header === null && !col.headerIcon && !col.headerIconColor'
            [ngClass]="{'pointer': col.sortBy}"
            [ngStyle]="{'min-width':col.width, 'max-width':col.width}">
            <div class='flex-container-row' style='width: 95%'>
                <div>
                    <div [title]='col.header|translate'
                         class='text-ellipsis font-table-header'>{{col.header| translate}}</div>
                    <div [hidden]='!col.unit' class='text-ellipsis'> ({{col.unit| translate}})</div>
                    <ion-icon *ngIf='col.headerIcon' color='{{col.headerIconColor}}'
                              name='{{col.headerIcon}}' class='headerIconPosition'></ion-icon>
                </div>
                <ion-icon *ngIf='col.sortOrderWeb && col.sortOrder === SortOrder.DESC && col.sortBy'
                          class='small-icon ion-text-end icon-no-margin'
                          name='chevron-down'></ion-icon>
                <ion-icon *ngIf='col.sortOrderWeb && col.sortOrder === SortOrder.ASC  && col.sortBy'
                          class=' icon-no-margin small-icon ion-text-end'
                          name='chevron-up'></ion-icon>
            </div>
        </th>
    </tr>
    </thead>
    <tbody *ngIf='isLoadingSuccess'>
    <ion-reorder-group (ionItemReorder)='doReorder($event)' [disabled]='_listTableConfig?.isReorderDisable'>
        <tr (click)='openDetailPage(rowIndex)'
            *ngFor='let row of _listTableConfig?.list?.items; let rowIndex = index'
            [hidden]='!_listTableConfig?.list?.items'
            [ngClass]="{'pointer': _listTableConfig.isOpenDetailEnable}" class='flex-container-row table-width'>
            <td (click)='emitActionItem($event, row, itemSetting.actionType, itemSetting)'
                *ngFor='let itemSetting of _listTableConfig.itemSettings| filterWeb;'
                [ngStyle]="{'min-width':itemSetting.width, 'max-width':itemSetting.width}" [hidden]='itemSetting.isHidden'
                class='full-height' style='display: flex; align-items: flex-start'>
                <curafida-column-definition (actionSelected)='emitActionSelection($event, row, itemSetting)'
                                            (updateList)='updateTable.emit(
                                            {offset:(this._listTableConfig.list.offset / this._listTableConfig.list.limit),
                                            limit: this._listTableConfig.list.limit})' [itemSetting]='itemSetting'
                                            [rowIndex]='rowIndex'
                                            [row]='row'
                                            class='full-width'>
                </curafida-column-definition>
            </td>
        </tr>
    </ion-reorder-group>
    </tbody>
    <ion-buttons
        *ngIf='isLoadingSuccess && _listTableConfig.list.items && !(_listTableConfig?.list?.offset === 0 && _listTableConfig?.list?.total  <= _listTableConfig?.list?.limit && _listTableConfig?.list?.count  <= _listTableConfig?.list?.limit)'
        class='flex-container-row justify-content-center'>
        <ion-button (click)='previousPage()' [disabled]='_listTableConfig?.list?.offset === 0'>
            <ion-icon name='chevron-back-outline'></ion-icon>
        </ion-button>
        <ion-button (click)='openTablePage(pageNumber)'
                    *ngFor='let pageNumber of paginationList'
                    [hidden]='paginationList.length >= this._listTableConfig.list.limit'
                    [ngClass]="{'active-button': pageNumber=== (_listTableConfig.list.offset/_listTableConfig.list.limit)}">
            {{pageNumber + 1}}
        </ion-button>
        <div *ngFor='let pageNumber of paginationList'
             [hidden]='paginationList.length < this._listTableConfig.list.limit'>
            <ion-button (click)='openTablePage(pageNumber)'
                        *ngIf='pageNumber < 5'
                        [ngClass]="{'active-button': pageNumber=== (_listTableConfig.list.offset/_listTableConfig.list.limit)}">
                {{pageNumber + 1}}
            </ion-button>
        </div>
        <div [hidden]='paginationList.length < this._listTableConfig.list.limit'>...</div>
        <div
            [hidden]='paginationList.length < this._listTableConfig.list.limit'>
            <ion-button *ngIf='!(startPaginationToShowList.includes(_listTableConfig.list.offset/_listTableConfig.list.limit)
            || endPaginationToShowList.includes(_listTableConfig.list.offset/_listTableConfig.list.limit))'
                        class='active-button'>{{(_listTableConfig.list.offset / _listTableConfig.list.limit) + 1}}
            </ion-button>
            ...
        </div>

        <div *ngFor='let pageNumber of paginationList'
             [hidden]='paginationList.length < this._listTableConfig.list.limit'>
            <ion-button (click)='openTablePage(pageNumber)'
                        *ngIf='pageNumber > paginationList.length - 6'
                        [ngClass]="{'active-button': pageNumber === (_listTableConfig.list.offset/_listTableConfig.list.limit)}">
                {{pageNumber + 1}}
            </ion-button>
        </div>
        <ion-button
            (click)='nextPage()'
            [disabled]='_listTableConfig?.list?.total - _listTableConfig?.list?.offset <= _listTableConfig?.list?.limit'>
            <ion-icon name='chevron-forward-outline'></ion-icon>
        </ion-button>
    </ion-buttons>
</table>


<!-- mobile version-->
<div *ngIf='isMobile && _listTableConfig.hasMobileConfig'>
    <curafida-column-definition  [mobileAdapter]='mobileAdapter'
                                 *ngFor='let mobileItems of mobileList.items; let rowIndex = index'
                                 (actionSelected)='emitActionSelection($event,mobileItems)'
                                 [rowIndex]='rowIndex'
                                 [isMobile]='isMobile'
                                 [row]='mobileItems'
                                 class='full-width'>
    </curafida-column-definition>
</div>
<ion-infinite-scroll (ionInfinite)='loadData($event)'
                     *ngIf='_listTableConfig.hasMobileConfig && isMobile && _listTableConfig.list.total > mobileList.limit'
                     threshold='100px'>
    <ion-infinite-scroll-content
        loadingSpinner='crescent'
        loadingText=''>
    </ion-infinite-scroll-content>
</ion-infinite-scroll>

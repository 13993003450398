import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Logger} from 'loglevel';
import {CurafidaAuthService} from '../auth/services';
import {LoggingService, LogLevel} from '../logging/logging.service';
import {SplashScreenStateService} from '../common/services/splash-screen-state.service';
import {UsersPreferencesService} from '../user/services/user-preferences';

@Injectable({
    providedIn: 'root',
})
export class MemberGuardService {
    protected readonly log: Logger;

    constructor(
        protected authService: CurafidaAuthService,
        private router: Router,
        private loggingService: LoggingService,
        private splashScreenStateService: SplashScreenStateService,
        private usersPreferencesService: UsersPreferencesService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
        this.log.setLevel(LogLevel.INFO);
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<UrlTree | boolean> {
        const isAuthenticated = await this.authService.isAuthenticated(false, true);
        if (isAuthenticated) {
            this.log.debug(`[canActivate] User is authenticated. Accessing /member/* is allowed. Return true.`);
            this.splashScreenStateService.stop();
            await this.usersPreferencesService.init(this.authService.getSession().user.username);
            return true;
        }

        this.log.info(`[canActivate] User is NOT authenticated. Navigate to login page`);
        this.splashScreenStateService.stop();
        return this.router.createUrlTree(['login'], {queryParams: {redirect: state.url}});
    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ExerciseSortOrder, Therapy, TherapyDto, TherapyExercise, TherapyState } from '../../entities/therapy';
import { Exercise } from '../../entities/exercise';
import { PaginatedResponse, SortBy, SortOrder } from '../../../common/entities/paginated-response';
import { ApiService } from '../../../api';
import { TherapyGoal } from '../../entities/therapy-goal/therapy-goal';
import { ExerciseType } from '../../entities/exerciseSession';
import { LoadingService } from '../../../common/services/loading/loading.service';

@Injectable({
    providedIn: 'root',
})
export class TherapiesService {
    constructor(public router: Router, protected http: HttpClient, private loadingService: LoadingService) {}

    async getTherapies(
        offset?: number,
        limit?: number,
        filter?: string,
        sortOrder?: SortOrder,
        therapyState?: TherapyState,
        sortBy?: SortBy,
        therapyGoal?: TherapyGoal,
        exerciseType?: ExerciseType,
    ): Promise<PaginatedResponse<Therapy[]>> {
        let url = `${ApiService.url}therapies`;

        // build query param string
        let queryParams = '';
        if (offset) queryParams = `${queryParams}offset=${offset}&`;
        if (limit) queryParams = `${queryParams}limit=${limit}&`;
        if (filter) queryParams = `${queryParams}filter=${filter}&`;
        if (sortBy) queryParams = `${queryParams}sortBy=${sortBy}&`;
        if (sortOrder) queryParams = `${queryParams}sortOrder=${sortOrder}&`;
        if (therapyGoal) queryParams = `${queryParams}therapyGoal=${therapyGoal}&`;
        if (exerciseType) queryParams = `${queryParams}exerciseType=${exerciseType}&`;
        if (therapyState) queryParams = `${queryParams}therapyState=${therapyState}&`;
        // check if query params are set, if so ...
        if (queryParams.length > 0) {
            // ... remove the last char '&' and append the query param string to the url
            queryParams = queryParams.substring(0, queryParams.length - 1);
            url = url + '?' + queryParams;
        }
        return this.http.get<PaginatedResponse<Therapy[]>>(url, ApiService.options).toPromise();
    }

    async getTherapyId(therapyId: number): Promise<Therapy> {
        const url = `${ApiService.url}therapies/${therapyId}`;
        return this.http.get<Therapy>(url, ApiService.options).toPromise();
    }

    async updateTherapy(therapyId: number, therapy: TherapyDto): Promise<Therapy> {
        this.loadingService.startLoadingModal();
        const url = `${ApiService.url}therapies/${therapyId}`;
        return this.http
            .put<Therapy>(url, therapy, ApiService.options)
            .toPromise()
            .finally(() => {
                this.loadingService.stopLoadingModal();
            });
    }

    async deleteTherapy(therapyId: number) {
        this.loadingService.startLoadingModal();
        const url = `${ApiService.url}therapies/${therapyId}`;
        return this.http
            .delete(url, ApiService.options)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async deleteExerciseOfTherapy(therapyExerciseId: number) {
        this.loadingService.startLoadingModal();
        const url = `${ApiService.url}therapies/-/therapyExercises/${therapyExerciseId}`;
        return this.http
            .delete(url, ApiService.options)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async postExerciseOfTherapy(therapyId: number, exerciseId: number): Promise<TherapyExercise> {
        this.loadingService.startLoadingModal();
        const url = `${ApiService.url}therapies/${therapyId}/therapyExercises/${exerciseId}`;
        return this.http
            .post<TherapyExercise>(url, '', ApiService.options)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async copyTherapiesFromTemplate(
        therapyTemplateId: number,
        usernames?: string[],
        createEmptySessions?: boolean,
        startDate?: Date,
        endDate?: Date,
        parentId?: number,
    ): Promise<Therapy[]> {
        this.loadingService.startLoadingModal();
        const url = new URL(`${ApiService.url}therapies/copyTherapiesFromTemplate/${therapyTemplateId}`);
        if (usernames)
            for (const username of usernames) {
                url.searchParams.set('usernames', username);
            }
        if (createEmptySessions) url.searchParams.set('createEmptySessions', createEmptySessions.toString());
        if (startDate) url.searchParams.set('startDate', startDate.toISOString());
        if (endDate) url.searchParams.set('endDate', endDate.toISOString());
        if (parentId) url.searchParams.set('parentId', parentId.toString());
        return this.http
            .post<Therapy[]>(url.toString(), null, ApiService.options)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async createTherapyFromTemplateForPatient(
        therapyTemplateId: number,
        username: string,
        createExerciseSessions = true,
        startDate?: string,
        endDate?: string,
    ): Promise<Therapy> {
        this.loadingService.startLoadingModal();
        const url = new URL(`${ApiService.url}users/${username}/therapies/copyFromTemplate/${therapyTemplateId}`);
        if (!createExerciseSessions) url.searchParams.set('createExerciseSessions', createExerciseSessions.toString());
        if (startDate) url.searchParams.set('startDate', startDate.toString());
        if (endDate) url.searchParams.set('endDate', endDate.toString());
        return this.http
            .post<Therapy>(url.toString(), '', ApiService.options)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async putTherapiesTherapyExercisesOrder(
        exerciseSortOrder: ExerciseSortOrder,
        therapyId: number,
    ): Promise<Exercise> {
        this.loadingService.startLoadingModal();
        const url = `${ApiService.url}therapies/${therapyId}/therapyExercises/orders`;
        return this.http
            .put<Exercise>(url, exerciseSortOrder, ApiService.options)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }
}

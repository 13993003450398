import { Injectable } from '@angular/core';
import { Entry, File } from '@ionic-native/file/ngx';
import { Logger, LoggingService } from '../../../logging/logging.service';
import { v4 as uuid } from 'uuid';
import { Platform } from '@ionic/angular';
import * as path from 'path';
import { HTTP } from '@ionic-native/http/ngx';

@Injectable({
    providedIn: 'root',
})
export class FileStorageService {
    protected readonly log: Logger;

    constructor(
        private loggingService: LoggingService,
        private filePlugin: File,
        private platform: Platform,
        private httpService: HTTP,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    /**
     * Deletes a file from local filesystem in platform specific data directory.
     * Works on iOS and Android, not in the browser.
     * @param fileName the file name without path
     */
    async deleteFile(fileName: string) {
        this.log.debug(`Deleting ${fileName} in ${this.filePlugin.dataDirectory}`);
        try {
            await this.filePlugin.removeFile(this.filePlugin.dataDirectory, fileName);
        } catch (err) {
            this.log.error(`Deleting file ${fileName} in ${this.filePlugin.dataDirectory} raised an error:`, err);
        }
        await this.ensureFileDoesNotExist(fileName);
    }

    /**
     * Use this method for debugging purpose.
     */
    private async logFilesFromDataDirectory(): Promise<Entry[]> {
        const entries = await this.filePlugin.listDir(this.filePlugin.dataDirectory, '');
        this.log.debug(`File entries from platform specific data directory "${this.getFileStorageDirectory()}":`);
        this.log.debug(entries);
        return entries;
    }

    private async ensureFileDoesNotExist(fileName: string) {
        try {
            const doesFileExist = await this.filePlugin.checkFile(this.getFileStorageDirectory(), fileName);
            if (!doesFileExist) {
                this.log.info(`The file ${fileName} in ${this.getFileStorageDirectory()} does not exist`);
                return true;
            }
        } catch (err) {
            if (err.message === 'NOT_FOUND_ERR') {
                return true;
            }
        }
        return false;
    }

    /**
     * Deletes and recreates the data directory.
     * All files in the data directory will be removed.
     */
    async clearDataDirectory(sure = false, reallySure = false) {
        if (!this.platform.is('cordova')) {
            this.log.warn('Deleting data directory only works on cordova platform');
            return;
        }
        if (!sure || !reallySure) {
            return;
        }
        const basePath = path.dirname(this.getFileStorageDirectory());
        const dirName = path.basename(this.getFileStorageDirectory());
        await this.filePlugin.removeRecursively(basePath, dirName);
        await this.filePlugin.createDir(basePath, dirName, true);
    }

    /**
     * Generates a file path with the following format:
     * [PLATFORM_DATA_DIRECTORY]/[prefix]_<random_uuid>.[fileExtension]
     */
    generateUniqueFilepath(prefix: string, fileExtension: string) {
        const fileName = prefix + `_` + uuid() + `.` + fileExtension;
        const filePath = this.getFileStorageDirectory() + fileName;
        return { fileName, filePath };
    }

    async getFilesFromDataDirectory() {
        const listFiles = await this.filePlugin.listDir(this.getFileStorageDirectory(), '');
        this.log.info(listFiles);
        return listFiles;
    }

    async checkFile(uploadFilename: string) {
        try {
            const checkedFile = await this.filePlugin.checkFile(this.getFileStorageDirectory(), uploadFilename);
            this.log.info(checkedFile);
            return checkedFile;
        } catch (e) {
            this.log.error('checkFile', e);
        }
    }

    async writeInTempFile(blob, name) {
        // const tempPath = this.file.tempDirectory;
        const tempPath = this.filePlugin.dataDirectory + '/temp/';
        this.log.info(tempPath);
        try {
            await this.filePlugin.writeFile(tempPath, name, blob);
        } catch (e) {
            this.log.error('writeInTempFile', e);
        }
    }

    public async writeFile(blob: any, fileName: string, location = this.filePlugin.dataDirectory) {
        try {
            await this.filePlugin.writeFile(location, fileName, blob);
        } catch (e) {
            this.log.error('Error while writing file', e);
        }
    }

    /**
     * Bitte nicht verwenden,für Browser-Anwendung!
     * The idea of this function is to get the most suitable FileStorage path depending on the Platform
     * Browser FileStorage is not yet clear, how should be implemented
     * If you are developing for Android you can use it.
     * Documentation: https://cordova.apache.org/docs/en/latest/reference/cordova-plugin-file/
     */
    getFileStorageDirectory() {
        if (this.platform.is('cordova')) {
            return this.filePlugin.dataDirectory;
        } else {
            return '';
        }
    }

    /**
     * Bitte nicht verwenden,für Browser-Anwendung!
     * Set a temporal Path into the suitable FileStoragePath depending on the Platform
     * Browser FileStoragePlugin is not yet clear, how should be implemented
     * If you are developing for Android, you can use it.
     * Documentation: https://cordova.apache.org/docs/en/latest/reference/cordova-plugin-file/
     */
    async getTempFileStorageDirectory() {
        let resp = null;
        try {
            resp = await this.filePlugin.checkDir(this.filePlugin.dataDirectory, 'temp');
        } catch (e) {
            this.log.warn('Temp File does not exist', e);
        }
        if (!resp) {
            await this.filePlugin.createDir(this.filePlugin.dataDirectory, 'temp', true);
        }
        if (this.platform.is('cordova')) {
            return this.filePlugin.dataDirectory + 'temp/';
        } else {
            return '';
        }
    }

    async downloadFileFromHttpLocationAndSaveIntoLocalPath(
        httpLocation: string,
        filename: string,
        localStoragePath: string = this.getFileStorageDirectory(),
    ) {
        try {
            return await this.httpService.downloadFile(httpLocation, null, {}, localStoragePath + '/' + filename);
        } catch (e) {
            this.log.debug('Downloading Error:', e);
        }
    }

    /**
     * Return true if the file was successfully moved
     * Return false if it was not possible
     * Input fileName
     */
    async moveFilesFromTempIntoPermanentPath(fileName: string): Promise<boolean> {
        try {
            const tempPath = await this.getTempFileStorageDirectory();
            await this.filePlugin.moveFile(tempPath, fileName, this.getFileStorageDirectory(), fileName);
            return true;
        } catch (e) {
            this.log.error('moveTempFilesIntoPermanentPath: ', fileName);
            // TODO: notificate the user about the error
            return false;

            // OPTION 4
            // Throw Error ()
        }
    }
}

<form *ngIf='trainingForm && training' [formGroup]='trainingForm'>
    <curafida-modal-select-input (click)='selectTrainingTemplate()' [formControlName]="'templateName'"
                                 [formGroup]='trainingForm'
                                 [hidden]='!isNew'
                                 [label]="'Trainingsplanvorlage' "></curafida-modal-select-input>
    <curafida-text-input [formControlName]="'templateName'" [formGroup]='trainingForm'
                         [hidden]='isNew'
                         [label]="'Trainingsplanvorlage'"></curafida-text-input>
    <curafida-text-input [formControlName]="'title'" [formGroup]='trainingForm'
                         [label]="'Planbezeichnung'"></curafida-text-input>
    <curafida-text-input [formControlName]="'trainingGoal'" [formGroup]='trainingForm'
                         [label]="'Zuordnung'"></curafida-text-input>
    <curafida-text-input [formControlName]="'created_at'" [formGroup]='trainingForm'
                         [label]="'Erstellt am'"></curafida-text-input>
    <curafida-date-input (inputBlur)='setEndDate()' [dateFormat]="'dd.MM.yyyy'"
                         [formControlName]="'startDate'"
                         [formGroup]='trainingForm'
                         [label]="'Startdatum'"></curafida-date-input>
    <curafida-date-input (inputBlur)='checkEndDate()' [dateFormat]="'dd.MM.yyyy'"
                         [min]='trainingForm.controls.startDate.value ? trainingForm.controls.startDate.value :null'
                         [formControlName]="'endDate'"
                         [formGroup]='trainingForm'
                         [label]="'Enddatum'"></curafida-date-input>
    <curafida-text-input [formControlName]="'therapySessionsPerWeek'"
                         [formGroup]='trainingForm'
                         [label]="'Einheiten pro Woche'"></curafida-text-input>
    <curafida-text-area [formControlName]="'description'" [formGroup]='trainingForm'
                        [label]="'Bemerkung'"></curafida-text-area>
    <div *ngIf='showActionButton' class='button-container flex-container-row justify-content-space-between'>
        <div>
            <ion-button (click)='showDeletePrompt()' *ngIf='!isNew' class='btn-font-medium'
                        color='danger'
                        fill='outline'>Trainingsplan löschen
            </ion-button>
        </div>
        <div>
            <curafida-save-cancel-button (cancelEditEmit)='cancelFormEdit()' (saveInformationEmit)='saveTraining()'
                                         (toggleEditEmit)='toggleFormEdit()' [element]='training'
                                         [formGroup]='trainingForm' [isEditEnabled]='isEditEnabled'
                                         [isNewElement]='isNew'
                                         [saveLabel]='"SAVE"'></curafida-save-cancel-button>
        </div>
    </div>
</form>

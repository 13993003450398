import { Tag } from '../../../therapy/entities/tag/tag.entity';
import { NoteTag } from '../../../note/administration/characteristic/note-tags.model';

export class PaginatedResponse<T> {
    role: string;
    count = 0;
    offset = 0;
    limit = 0;
    items: T;
    filterByCols: string[];
    total = 0;
    sortBy: string;
    sortOrder: string;
    filter: string;
    tags: NoteTag[] | Tag[];
}

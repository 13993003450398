<form [formGroup]="rangeSelectForm" *ngIf="rangeSelectForm && rangeValue">
    <div *ngIf="!isVertical" class="flex-container-row align-items-center">
        <ion-button [disabled]="rangeValue.disabled" color="primary" (press)="pressEvent(SelectorAction.LOWER)"
                    (pressup)="pressUpEvent()" (ionBlur)="pressUpEvent()" #buttonDown (click)="clickEvent(SelectorAction.LOWER)"
                    size="small">
            <ion-icon name="remove"></ion-icon>
        </ion-button>
        <ion-input [inputmode]="'decimal'" (ionBlur)="sendValue()"
                   [formControlName]="'inputValue'" [disabled]="rangeValue.disabled" [max]="rangeValue.max"
                   [min]="rangeValue.min"></ion-input>
        <ion-button [disabled]="rangeValue.disabled" color="primary" (press)="pressEvent(SelectorAction.UPPER)"
                    (pressup)="pressUpEvent()" (ionBlur)="pressUpEvent()" #buttonUp (click)="clickEvent(SelectorAction.UPPER)"
                    size="small">
            <ion-icon name="add" style="font-size: 2rem"></ion-icon>
        </ion-button>
    </div>
</form>
<form [formGroup]="rangeSelectForm" *ngIf="rangeSelectForm && rangeValue">
    <div *ngIf="isVertical" class="flex-container-column">
        <ion-button [disabled]="rangeValue.disabled" color="primary" (press)="pressEvent(SelectorAction.UPPER)"
                    (pressup)="pressUpEvent()" (ionBlur)="pressUpEvent()" (click)="clickEvent(SelectorAction.UPPER)"
                    size="small">
            <ion-icon name="add" style="font-size: 2rem"></ion-icon>
        </ion-button>
        <ion-input (ionBlur)="sendValue()" [formControlName]="'inputValue'"
                   [disabled]="rangeValue.disabled" [inputmode]="'decimal'" [max]="rangeValue.max"
                   [min]="rangeValue.min"></ion-input>
        <ion-button [disabled]="rangeValue.disabled" color="primary" (press)="pressEvent(SelectorAction.LOWER)"
                    (pressup)="pressUpEvent()" (ionBlur)="pressUpEvent()" (click)="clickEvent(SelectorAction.LOWER)"
                    size="small">
            <ion-icon name="remove"></ion-icon>
        </ion-button>
    </div>
</form>

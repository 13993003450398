import { UserRoles } from '../../auth/entities/user';
import { AppConfigBaseClass } from './app-config-interface';
import { RoutingSegment } from '../../common/entities/routing-segment';
import { PageName } from './page.name';
import { CurafidaFeature } from './base-page-config';

export class AppTemplateConfig extends AppConfigBaseClass {
    appDisplayName = 'Curafida';
    userRoleOfBetreuer = UserRoles.CAREGIVER;
    rolesOfBetreuer = [UserRoles.CAREGIVER];
    toolbarLogoWhiteBackground = true;
    defaultUrl = '/default';
    appVersion = '';

    features = {};

    generalPageConfigs = [
       /* {
            title: 'Handbuch',
            pageId: 'manual',
            categories: [RoutingSegment.MANUAL],
            configuration: {
                menuVisibility: true,
            },
            role: [],
            url: 'common/manual',
        },*/
        {
            title: 'Rechtliches',
            pageId: `${PageName.COMMON}_${PageName.MAIN}`,
            categories: [RoutingSegment.ADMINISTRATION],
            role: [],
            configuration: {
                menuVisibility: true,
            },
            childPageConfigs: [
                {
                    title: 'Datenschutz',
                    url: `${RoutingSegment.COMMON}/${RoutingSegment.PRIVACY}`,
                    categories: [RoutingSegment.PRIVACY],
                    pageId: PageName.PRIVACY,
                    role: [],
                    configuration: {
                        menuVisibility: true,
                        showPageTitle: true,
                    },
                },
                {
                    title: 'Nutzungsvereinbarung ',
                    url: '/common/user-agreement',
                    categories: [RoutingSegment.USER_AGREEMENT],
                    pageId: PageName.USER_AGREEMENT,
                    role: [],
                    configuration: {
                        menuVisibility: true,
                        showPageTitle: true,
                    },
                },
                {
                    title: 'Impressum',
                    url: '/common/imprint',
                    categories: [RoutingSegment.IMPRINT],
                    role: [],
                    configuration: {
                        menuVisibility: true,
                        showPageTitle: true,
                    },
                },
                {
                    title: 'Version-Information',
                    url: '/common/version-info',
                    categories: [RoutingSegment.IMPRINT],
                    role: [],
                    configuration: {
                        showPageTitle: true,
                    },
                },
                {
                    title: 'DECLARATION_OF_CONFORMITY',
                    url: `/${RoutingSegment.COMMON}/${RoutingSegment.CONFORMITY}`,
                    categories: [RoutingSegment.CONFORMITY],
                    pageId: PageName.CONFORMITY,
                    role: [],
                    configuration: {
                        menuVisibility: true,
                        showPageTitle: true,
                    },
                },
                {
                    title: 'TERMS_AND_CONDITIONS.SHORT',
                    url: `/${RoutingSegment.COMMON}/${RoutingSegment.TERMS_AND_CONDITIONS}`,
                    categories: [RoutingSegment.CONFORMITY],
                    module: CurafidaFeature.shop,
                    pageId: PageName.CONFORMITY,
                    role: [],
                    configuration: {
                        menuVisibility: true,
                        showPageTitle: true,
                    },
                },
            ],
        },
        {
            title: 'Kontakt',
            url: '/common/contact',
            categories: [RoutingSegment.CONTACT],
            pageId: PageName.CONTACT,
            role: [],
            text: `Bei Fragen zum Projekt können Sie sich gerne mit einer E-Mail an <a href='mailto:support@ztm.de' target='_blank'>support@ztm.de</a> wenden.`,
            configuration: {
                menuVisibility: true,
                showPageTitle: true,
            },
        },
    ];
    isUsingMyMedax = false;
    rootUrls = [
        {
            url: `${RoutingSegment.MEMBER}/${RoutingSegment.HOME}`,
            role: UserRoles.ANALYST,
        },
        {
            url: `${RoutingSegment.MEMBER}/${RoutingSegment.HOME}`,
            role: UserRoles.CAREGIVER,
        },
        {
            url: `${RoutingSegment.MEMBER}/${RoutingSegment.HOME}`,
            role: UserRoles.CATALOG_MANAGER,
        },
        {
            url: `${RoutingSegment.MEMBER}/${RoutingSegment.PATIENT_MANAGEMENT}/${RoutingSegment.HOME}`,
            role: UserRoles.PATIENT,
        },
        {
            url: `${RoutingSegment.MEMBER}/${RoutingSegment.HOME}`,
            role: UserRoles.SUPERVISOR,
        },
        {
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.ADMINISTRATION}/${RoutingSegment.USER}/${RoutingSegment.LIST}`,
            role: UserRoles.USER_MANAGER,
        },
    ];

    pageConfigs = [
        {
            title: 'Übersicht',
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.HOME}`,
            pageId: PageName.HOME,
            role: [
                UserRoles.ANALYST,
                UserRoles.CAREGIVER,
                UserRoles.CATALOG_MANAGER,
                UserRoles.SUPERVISOR,
                UserRoles.USER_MANAGER,
            ],
            categories: [RoutingSegment.HOME, RoutingSegment.THERAPY],
            module: CurafidaFeature.common,
            configuration: {
                menuVisibility: true,
                headerVisibility: true,
                showPageTitle: true,
            },
        },
        {
            title: 'Kiosk',
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.KIOSK}`,
            pageId: 'KIOSK',
            role: [UserRoles.PATIENT],
            categories: [RoutingSegment.HOME],
            module: CurafidaFeature.common,
            configuration: {},
        },
        {
            title: 'Übersicht',
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.PATIENT_MANAGEMENT}/${RoutingSegment.HOME}`,
            pageId: `${PageName.PATIENT}_${PageName.HOME}`,
            role: [UserRoles.PATIENT],
            categories: [RoutingSegment.HOME],
            module: CurafidaFeature.common,
            configuration: {
                menuVisibility: true,
                headerVisibility: true,
                showPageTitle: true,
                hasInAppNotifications: true,
                showQuestionnaireChart: false,
            },
        },
        {
            title: 'Anmelden',
            url: '/login',
            pageId: 'LOGIN',
            role: [],
            categories: [RoutingSegment.LOGIN],
            configuration: {},
        },
        {
            title: 'Registrieren',
            url: `${RoutingSegment.REGISTER}`,
            pageId: 'REGISTER',
            role: [],
            categories: [RoutingSegment.REGISTER],
            configuration: { menuVisibility: true, headerVisibility: true },
            module: CurafidaFeature.shop,
        },
        {
            title: 'Katalog',
            url: `${RoutingSegment.MEMBER}/${RoutingSegment.SHOP}/${RoutingSegment.MAIN}`,
            pageId: 'SHOP_MAIN',
            role: [UserRoles.PATIENT],
            categories: [RoutingSegment.SHOP],
            configuration: { menuVisibility: true, headerVisibility: true },
            module: CurafidaFeature.shop,
        },
        {
            title: 'Article',
            url: `${RoutingSegment.SHOP}/${RoutingSegment.ARTICLE}`,
            pageId: `${RoutingSegment.MEMBER}/${RoutingSegment.SHOP}_${RoutingSegment.ARTICLE}`,
            role: [UserRoles.PATIENT],
            categories: [RoutingSegment.SHOP],
            configuration: { showPageTitle: true },
            module: CurafidaFeature.shop,
        },
        {
            title: 'Katalog',
            url: `${RoutingSegment.SHOP}/${RoutingSegment.MAIN}`,
            pageId: 'SHOP_MAIN',
            role: [],
            categories: [RoutingSegment.SHOP],
            configuration: { menuVisibility: true, headerVisibility: true },
            module: CurafidaFeature.shop,
        },
        {
            title: 'Article',
            url: `${RoutingSegment.SHOP}/${RoutingSegment.ARTICLE}`,
            pageId: `${RoutingSegment.SHOP}_${RoutingSegment.ARTICLE}`,
            role: [],
            categories: [RoutingSegment.SHOP],
            configuration: { showPageTitle: true },
            module: CurafidaFeature.shop,
        },
        {
            title: 'Patienten',
            pageId: `${PageName.PATIENT}_${PageName.LIST}`,
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.PATIENT_MANAGEMENT}/${RoutingSegment.LIST}`,
            categories: [RoutingSegment.PATIENT_MANAGEMENT],
            role: [UserRoles.READ_USER, UserRoles.SUPERVISOR, UserRoles.READ_PATIENT],
            module: CurafidaFeature.user,
            configuration: {
                menuVisibility: true,
                headerVisibility: true,
                showPageTitle: true,
            },
        },
        {
            title: 'Chat',
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.CHAT}/${RoutingSegment.MAIN}`,
            pageId: PageName.CHAT,
            role: [UserRoles.MANAGE_CHAT],
            categories: [RoutingSegment.CHAT],
            module: CurafidaFeature.chat,
            configuration: {
                showPageTitle: true,
            },
        },
        {
            title: 'Chat',
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.PATIENT_MANAGEMENT}/${RoutingSegment.CHAT}/${RoutingSegment.MAIN}`,
            pageId: PageName.PATIENT_CHAT,
            role: [UserRoles.PATIENT],
            categories: [RoutingSegment.CHAT],
            module: CurafidaFeature.chat,
            configuration: {
                showPageTitle: true,
                menuVisibility: true,
            },
        },
        {
            title: 'Patient',
            pageId: PageName.PATIENT_DETAIL,
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.PATIENT_MANAGEMENT}/${RoutingSegment.DETAIL}`,
            categories: [RoutingSegment.PATIENT_MANAGEMENT],
            module: CurafidaFeature.user,
            role: [UserRoles.READ_USER, UserRoles.SUPERVISOR, UserRoles.READ_PATIENT],
            configuration: {
                showPageTitle: true,
                hasNationality: true,
                hasMaritalStatus: true,
                hasGeneralDoctor: true,
                hasEmail: true,
                // isEmailObligatory deprecated
                isEmailObligatory: true,
                hasGender: true,
                hasBirthdate: true,
                hasHealthInsurance: true,
                hasPhone: true,
                showUsername: true,
                hasUserTitle: true,
                isDeleteButtonShow: true,
                hasRoleManagement: true,
                hasPseudonym: true,
                hasTypeOfInsurer: true,
                hasCaregiverManagement: true,
                hasSupervisorManagement: true,
                hasTagManagement: true,
            },
        },
        {
            title: 'Patient',
            pageId: PageName.PATIENT_DETAIL_SHORT,
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.PATIENT_MANAGEMENT}/${RoutingSegment.DETAIL}/${RoutingSegment.SHORT}`,
            categories: [RoutingSegment.PATIENT_MANAGEMENT],
            module: CurafidaFeature.user,
            role: [UserRoles.READ_USER, UserRoles.SUPERVISOR],
            configuration: {
                showPageTitle: true,
                hasNationality: true,
                hasMaritalStatus: true,
                hasGender: true,
                hasBirthdate: true,
                hasHealthInsurance: true,
                hasUserTitle: true,
                isDeleteButtonShow: true,
                hasPseudonym: true,
                hasTypeOfInsurer: true,
                isBirthdateObligatory: true,
                hasCaregiverManagement: false,
                hasSupervisorManagement: false,
                hasTagManagement: false,
            },
        },
        {
            title: 'Kurse detail',
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.COURSE_MANAGEMENT}/${RoutingSegment.THERAPY}/${RoutingSegment.DETAIL}`,
            categories: [RoutingSegment.HOME],
            module: CurafidaFeature.course,
            role: [UserRoles.READ_THERAPY],
            pageId: PageName.THERAPY_DETAIL,
            configuration: {
                showPageTitle: true,
                hasTitle: true,
                hasDescription: true,
                hasArticleProperties: true,
            },
        },
        {
            title: 'Sitzungen',
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.PATIENT_MANAGEMENT}/${RoutingSegment.APPOINTMENT_MANAGEMENT}/${RoutingSegment.MAIN}`,
            categories: [RoutingSegment.HOME],
            module: CurafidaFeature.course,
            role: [UserRoles.PATIENT],
            pageId: `${RoutingSegment.APPOINTMENT_MANAGEMENT}_${RoutingSegment.MAIN}`,
            configuration: {
                showPageTitle: false,
                menuVisibility: false,
            },
        },
        {
            title: 'Manual',
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.CONCEPT}/${RoutingSegment.MAIN}`,
            categories: [RoutingSegment.CONCEPT],
            module: CurafidaFeature.manual,
            role: [UserRoles.MANAGE_THERAPY, UserRoles.SUPERVISOR],
            pageId: PageName.MANUAL,
            configuration: {
                menuVisibility: true,
                headerVisibility: true,
                showPageTitle: true,
            },
        },
        {
            title: 'Verwaltung',
            pageId: PageName.ADMINISTRATION,
            categories: [RoutingSegment.ADMINISTRATION, RoutingSegment.USER_MANAGEMENT],
            role: [UserRoles.READ_GROUP, UserRoles.MANAGE_USER, UserRoles.MANAGE_THERAPY_TEMPLATE],
            configuration: {
                menuVisibility: true,
                headerVisibility: true,
            },
            childPageConfigs: [
                // USER
                {
                    title: 'Benutzer',
                    pageId: PageName.USER_LIST,
                    url:
                        `/${RoutingSegment.MEMBER}/${RoutingSegment.ADMINISTRATION}/` +
                        `${RoutingSegment.USER}/${RoutingSegment.LIST}`,
                    categories: [RoutingSegment.ADMINISTRATION],
                    module: CurafidaFeature.user,
                    role: [UserRoles.MANAGE_USER],
                    configuration: {
                        menuVisibility: true,
                        headerVisibility: true,
                        showPageTitle: true,
                    },
                },
                {
                    title: 'Betreuer',
                    pageId: PageName.THERAPIST,
                    url:
                        `/${RoutingSegment.MEMBER}/${RoutingSegment.ADMINISTRATION}/${RoutingSegment.USER_MANAGEMENT}` +
                        `${RoutingSegment.CAREGIVER_MANAGEMENT}/${RoutingSegment.DETAIL}`,
                    categories: [RoutingSegment.ADMINISTRATION],
                    module: CurafidaFeature.user,
                    role: [UserRoles.READ_USER],
                    configuration: {
                        showPageTitle: true,
                        hasEmail: true,
                        // isEmailObligatory deprecated
                        isEmailObligatory: true,
                        hasPhone: true,
                        showUsername: true,
                        hasUserTitle: true,
                        hasRoleManagement: true,
                        hasTagManagement: true,
                    },
                },
                {
                    title: 'Analyst',
                    pageId: PageName.ANALYST,
                    url:
                        `/${RoutingSegment.MEMBER}/${RoutingSegment.ADMINISTRATION}/${RoutingSegment.USER_MANAGEMENT}` +
                        `${UserRoles.ANALYST}/${RoutingSegment.DETAIL}`,
                    categories: [RoutingSegment.ADMINISTRATION],
                    module: CurafidaFeature.user,
                    role: [UserRoles.READ_USER],
                    configuration: {
                        showPageTitle: true,
                        hasEmail: true,
                        // isEmailObligatory deprecated
                        isEmailObligatory: true,
                        hasPhone: true,
                        showUsername: true,
                        hasUserTitle: true,
                        hasRoleManagement: true,
                        hasTagManagement: true,
                    },
                },
                {
                    title: 'Co-Betreuer',
                    pageId: PageName.SUPERVISOR_DETAIL,
                    url:
                        `/${RoutingSegment.MEMBER}/${RoutingSegment.ADMINISTRATION}/` +
                        `${RoutingSegment.SUPERVISOR_MANAGEMENT}/${RoutingSegment.DETAIL}`,
                    categories: [RoutingSegment.ADMINISTRATION],
                    module: CurafidaFeature.user,
                    role: [UserRoles.MANAGE_SUPERVISOR],
                    configuration: {
                        showPageTitle: true,
                        hasEmail: true,
                        // isEmailObligatory deprecated
                        isEmailObligatory: true,
                        hasPhone: true,
                        showUsername: true,
                        hasUserTitle: true,
                        hasContactCategory: true,
                        hasTagManagement: true,
                    },
                },

                // ORGANISATION
                {
                    title: 'Organisationen',
                    pageId: PageName.ORGANIZATION_MANAGEMENT,
                    url: `/${RoutingSegment.MEMBER}/${RoutingSegment.ADMINISTRATION}/${RoutingSegment.ORGANIZATION}/${RoutingSegment.LIST}`,
                    categories: [RoutingSegment.ADMINISTRATION],
                    module: CurafidaFeature.organisation,
                    role: [UserRoles.READ_GROUP],
                    configuration: {
                        menuVisibility: true,
                        headerVisibility: true,
                        showPageTitle: true,
                    },
                },
                {
                    title: 'Organisation',
                    pageId: PageName.ORGANIZATION_DETAIL,
                    url: `/${RoutingSegment.MEMBER}/${RoutingSegment.ADMINISTRATION}/${RoutingSegment.ORGANIZATION}/${RoutingSegment.DETAIL}`,
                    categories: [RoutingSegment.ADMINISTRATION],
                    module: CurafidaFeature.organisation,
                    role: [UserRoles.READ_GROUP],
                    configuration: {
                        showPageTitle: true,
                    },
                },
                {
                    title: 'Gruppe',
                    pageId: PageName.GROUP_DETAIL,
                    url: `/${RoutingSegment.MEMBER}/${RoutingSegment.ADMINISTRATION}/${RoutingSegment.ORGANIZATION}/${RoutingSegment.GROUP}/${RoutingSegment.DETAIL}`,
                    categories: [RoutingSegment.ADMINISTRATION],
                    module: CurafidaFeature.organisation,
                    role: [UserRoles.READ_GROUP],
                    configuration: {
                        showPageTitle: true,
                    },
                },

                // TRAINING
                {
                    title: 'Training',
                    pageId: `${PageName.TRAINING}_${PageName.LIST}`,
                    url:
                        `/${RoutingSegment.MEMBER}/${RoutingSegment.ADMINISTRATION}/` +
                        `${RoutingSegment.TRAINING_MANAGEMENT}/${RoutingSegment.MAIN}`,
                    categories: [RoutingSegment.TRAINING_MANAGEMENT],
                    module: CurafidaFeature.training,
                    role: [UserRoles.MANAGE_THERAPY_TEMPLATE],
                    configuration: {
                        menuVisibility: true,
                        headerVisibility: true,
                        showPageTitle: true,
                    },
                },
                {
                    title: 'Training Detail',
                    pageId: `${PageName.TRAINING}_${PageName.DETAIL}`,
                    url: `/${RoutingSegment.MEMBER}/${RoutingSegment.PATIENT_MANAGEMENT}/${RoutingSegment.TRAINING_MANAGEMENT}/${RoutingSegment.DETAIL}`,
                    categories: [RoutingSegment.PATIENT_MANAGEMENT],
                    module: CurafidaFeature.training,
                    role: [UserRoles.READ_THERAPY],
                    configuration: {
                        showPageTitle: true,
                    },
                },
                {
                    title: 'Trainingsvorlage',
                    pageId: `${PageName.TRAINING}_${PageName.TEMPLATE}_${PageName.DETAIL}`,
                    url: `/${RoutingSegment.MEMBER}/${RoutingSegment.ADMINISTRATION}/${RoutingSegment.TRAINING_MANAGEMENT}/${RoutingSegment.TRAINING_TEMPLATE}`,
                    categories: [RoutingSegment.TRAINING_MANAGEMENT],
                    module: CurafidaFeature.training,
                    role: [UserRoles.MANAGE_THERAPY_TEMPLATE, UserRoles.MANAGE_THERAPY],
                    configuration: {
                        showPageTitle: true,
                    },
                },
                {
                    title: 'Übung detail',
                    pageId: `${PageName.TRAINING}_${PageName.EXERCISE}_${PageName.DETAIL}`,
                    url: `/${RoutingSegment.MEMBER}/${RoutingSegment.ADMINISTRATION}/${RoutingSegment.TRAINING_MANAGEMENT}/${RoutingSegment.EXERCISE}/${RoutingSegment.DETAIL}`,
                    categories: [RoutingSegment.ADMINISTRATION],
                    module: CurafidaFeature.training,
                    role: [UserRoles.MANAGE_EXERCISE, UserRoles.MANAGE_THERAPY],
                    configuration: {
                        showPageTitle: true,
                        hasTitle: true,
                        hasDescription: true,
                        hasDuration: true,
                        hasType: true,
                        hasContent: true,
                    },
                },
                {
                    title: 'Progression',
                    pageId: `${PageName.TRAINING}_${PageName.PROGRESSION}_${PageName.DETAIL}`,
                    url: `/${RoutingSegment.MEMBER}/${RoutingSegment.ADMINISTRATION}/${RoutingSegment.TRAINING_MANAGEMENT}/${RoutingSegment.PROGRESSION}/${RoutingSegment.DETAIL}`,
                    categories: [RoutingSegment.TRAINING_MANAGEMENT],
                    module: CurafidaFeature.training,
                    role: [UserRoles.MANAGE_THERAPY],
                    configuration: {
                        showPageTitle: true,
                    },
                },

                // TASK
                {
                    title: 'Aufgaben',
                    pageId: `${PageName.TASK}_${PageName.LIST}`,
                    url:
                        `/${RoutingSegment.MEMBER}/${RoutingSegment.ADMINISTRATION}/` +
                        `${RoutingSegment.TASK_MANAGEMENT}/${RoutingSegment.MAIN}`,
                    categories: [RoutingSegment.TASK_MANAGEMENT],
                    module: CurafidaFeature.task,
                    role: [UserRoles.MANAGE_THERAPY_TEMPLATE],
                    configuration: {
                        menuVisibility: true,
                        headerVisibility: true,
                        showPageTitle: true,
                    },
                },
                {
                    title: 'Aufgabenvorlage',
                    pageId: `${PageName.TASK}_${PageName.EXERCISE}_${PageName.DETAIL}`,
                    url: `/${RoutingSegment.MEMBER}/${RoutingSegment.ADMINISTRATION}/${RoutingSegment.TASK_MANAGEMENT}/${RoutingSegment.TASK_TEMPLATES}/${RoutingSegment.DETAIL}`,
                    categories: [RoutingSegment.ADMINISTRATION],
                    module: CurafidaFeature.task,
                    role: [UserRoles.MANAGE_EXERCISE],
                    configuration: {
                        showPageTitle: true,
                    },
                },
                {
                    title: 'Aufgabenplanvorlage',
                    pageId: `${PageName.TASK}_${PageName.TEMPLATE}_${PageName.DETAIL}`,
                    url: `/${RoutingSegment.MEMBER}/${RoutingSegment.ADMINISTRATION}/${RoutingSegment.TASK_MANAGEMENT}/${RoutingSegment.TASK_PLAN_TEMPLATES}/${RoutingSegment.DETAIL}`,
                    categories: [RoutingSegment.ADMINISTRATION],
                    module: CurafidaFeature.task,
                    role: [UserRoles.MANAGE_THERAPY_TEMPLATE],
                    configuration: {
                        showPageTitle: true,
                    },
                },

                // Learning
                {
                    title: 'Lernen',
                    pageId: `${PageName.LEARNING}_${PageName.MAIN}`,
                    url: `/${RoutingSegment.MEMBER}/${RoutingSegment.ADMINISTRATION}/${RoutingSegment.LEARNING_MANAGEMENT}/${RoutingSegment.MAIN}`,
                    categories: [RoutingSegment.LEARNING_MANAGEMENT],
                    module: CurafidaFeature.learning,
                    role: [UserRoles.MANAGE_THERAPY_TEMPLATE],
                    configuration: {
                        menuVisibility: true,
                        headerVisibility: true,
                        showPageTitle: true,
                    },
                },
                // PROGRAM
                {
                    title: 'Programme',
                    pageId: `${PageName.PROGRAM}_${PageName.LIST}`,
                    url:
                        `/${RoutingSegment.MEMBER}/${RoutingSegment.ADMINISTRATION}/` +
                        `${RoutingSegment.PROGRAM_MANAGEMENT}/${RoutingSegment.MAIN}`,
                    categories: [RoutingSegment.PROGRAM_MANAGEMENT],
                    module: CurafidaFeature.program,
                    role: [UserRoles.MANAGE_THERAPY_TEMPLATE],
                    configuration: {
                        menuVisibility: true,
                        headerVisibility: true,
                        showPageTitle: true,
                    },
                },
                {
                    title: 'Programmvorlage',
                    pageId: `${PageName.PROGRAM}_${PageName.TEMPLATE}_${PageName.DETAIL}`,
                    url: `/${RoutingSegment.MEMBER}/${RoutingSegment.ADMINISTRATION}/${RoutingSegment.PROGRAM_MANAGEMENT}/${RoutingSegment.PROGRAM_TEMPLATES}/${RoutingSegment.DETAIL}`,
                    categories: [RoutingSegment.ADMINISTRATION],
                    module: CurafidaFeature.program,
                    role: [UserRoles.MANAGE_THERAPY, UserRoles.MANAGE_THERAPY_TEMPLATE],
                    configuration: {
                        showPageTitle: true,
                    },
                },

                // COURSE
                {
                    title: 'Sitzungen',
                    url: `/${RoutingSegment.MEMBER}/${RoutingSegment.ADMINISTRATION}/${RoutingSegment.COURSE_MANAGEMENT}/${RoutingSegment.MAIN}`,
                    categories: [RoutingSegment.ADMINISTRATION],
                    module: CurafidaFeature.course,
                    pageId: PageName.COURSE_ADMINISTRATION,
                    role: [UserRoles.MANAGE_THERAPY_TEMPLATE],
                    configuration: {
                        menuVisibility: true,
                        headerVisibility: true,
                        showPageTitle: true,
                        hasAppointmentPlanSegment: true,
                    },
                },
                {
                    title: 'Übungen detail',
                    pageId: PageName.EXERCISE_DETAIL,
                    url:
                        `/${RoutingSegment.MEMBER}/${RoutingSegment.ADMINISTRATION}/${RoutingSegment.COURSE_MANAGEMENT}/` +
                        `${RoutingSegment.EXERCISE}/${RoutingSegment.APPOINTMENT_PLAN}`,
                    categories: [RoutingSegment.COURSE_MANAGEMENT, RoutingSegment.EXERCISE],
                    module: CurafidaFeature.course,
                    role: [UserRoles.MANAGE_EXERCISE, UserRoles.MANAGE_THERAPY],
                    configuration: {
                        showPageTitle: true,
                        hasTitle: true,
                        hasDescription: true,
                        hasDuration: true,
                        hasType: true,
                        hasTypeGroup: true,
                        hasTypeSingle: true,
                        hasContent: true,
                        hasAlternativeAppointment: true,
                    },
                },
                {
                    title: 'Sitzungsvorlage detail',
                    pageId: PageName.EXERCISE_DETAIL,
                    url: `/${RoutingSegment.MEMBER}/${RoutingSegment.ADMINISTRATION}/${RoutingSegment.COURSE_MANAGEMENT}/
                    ${RoutingSegment.EXERCISE}/${RoutingSegment.DETAIL}`,
                    categories: [RoutingSegment.COURSE_MANAGEMENT, RoutingSegment.EXERCISE],
                    module: CurafidaFeature.course,
                    role: [UserRoles.MANAGE_THERAPY],
                    configuration: {
                        showPageTitle: true,
                        hasTitle: true,
                        hasDescription: true,
                        hasDuration: true,
                        hasType: true,
                        hasTypeGroup: true,
                        hasTypeSingle: true,
                        hasContent: true,
                    },
                },
                {
                    title: 'Kursvorlagen detail',
                    url: `/${RoutingSegment.MEMBER}/${RoutingSegment.ADMINISTRATION}/${RoutingSegment.COURSE_MANAGEMENT}/${RoutingSegment.THERAPY_TEMPLATE}/${RoutingSegment.DETAIL}`,
                    categories: [RoutingSegment.COURSE_MANAGEMENT, RoutingSegment.THERAPY_TEMPLATE],
                    module: CurafidaFeature.course,
                    pageId: PageName.THERAPY_TEMPLATE_DETAIL,
                    role: [UserRoles.MANAGE_THERAPY_TEMPLATE],
                    configuration: {
                        showPageTitle: true,
                        hasArticleProperties: true,
                    },
                },
                {
                    title: 'Notizen',
                    pageId: `${PageName.NOTE}`,
                    url: `/${RoutingSegment.MEMBER}/${RoutingSegment.ADMINISTRATION}/${RoutingSegment.NOTE_MANAGEMENT}`,
                    categories: [RoutingSegment.NOTE_MANAGEMENT],
                    module: CurafidaFeature.note,
                    role: [UserRoles.MANAGE_NOTE_CHARACTERISTICS],
                    configuration: {
                        menuVisibility: true,
                        headerVisibility: true,
                        showPageTitle: true,
                    },
                },
            ],
        },
        //TODO: Remove when the new patient app works good
        {
            title: 'Training',
            pageId: `${PageName.TRAINING}_${PageName.PATIENT}_${PageName.LIST}`,
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.PATIENT_MANAGEMENT}/${RoutingSegment.TRAINING_MANAGEMENT}/${RoutingSegment.LIST}`,
            categories: [RoutingSegment.TRAINING_MANAGEMENT],
            module: CurafidaFeature.training,
            role: [UserRoles.PATIENT],
            configuration: {
                showPageTitle: false,
                menuVisibility: false,
            },
        },
        {
            title: 'Training',
            pageId: `${PageName.TRAINING}_${PageName.PATIENT}_${PageName.AUTO}`,
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.PATIENT_MANAGEMENT}/${RoutingSegment.TRAINING_MANAGEMENT}/${RoutingSegment.AUTO}`,
            categories: [RoutingSegment.TRAINING_MANAGEMENT],
            module: CurafidaFeature.training,
            role: [UserRoles.PATIENT],
            showTitle: true,
            configuration: {
                showPageTitle: true,
            },
        },
        {
            title: 'Training',
            pageId: `${PageName.TRAINING}_${PageName.PATIENT}_${PageName.AUTO}`,
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.CAREGIVER_MANAGEMENT}/${RoutingSegment.TRAINING_MANAGEMENT}/${RoutingSegment.AUTO}`,
            categories: [RoutingSegment.TRAINING_MANAGEMENT],
            module: CurafidaFeature.training,
            role: [UserRoles.MANAGE_THERAPY],
            showTitle: true,
            configuration: {
                showPageTitle: true,
            },
        },
        {
            title: 'Training',
            pageId: `${PageName.TRAINING}_${PageName.THERAPIST}_${PageName.INFO}`,
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.CAREGIVER_MANAGEMENT}/${RoutingSegment.TRAINING_MANAGEMENT}/${RoutingSegment.INFO}`,
            categories: [RoutingSegment.TRAINING_MANAGEMENT],
            module: CurafidaFeature.training,
            role: [UserRoles.MANAGE_THERAPY],
            showTitle: true,
            configuration: {
                showPageTitle: true,
            },
        },
        {
            title: 'Training',
            pageId: `${PageName.TRAINING}_${PageName.PATIENT}_${PageName.INFO}`,
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.PATIENT_MANAGEMENT}/${RoutingSegment.TRAINING_MANAGEMENT}/${RoutingSegment.INFO}`,
            categories: [RoutingSegment.TRAINING_MANAGEMENT],
            module: CurafidaFeature.training,
            role: [UserRoles.PATIENT],
            showTitle: true,
            configuration: {
                showPageTitle: true,
            },
        },
        {
            title: 'Training',
            pageId: `${PageName.TRAINING}_${PageName.MANUAL}_${PageName.AUTO}`,
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.CONCEPT}/${RoutingSegment.TRAINING_MANAGEMENT}/${RoutingSegment.AUTO}`,
            categories: [RoutingSegment.TRAINING_MANAGEMENT],
            module: CurafidaFeature.training,
            role: [UserRoles.MANAGE_THERAPY],
            showTitle: true,
            configuration: {
                showPageTitle: true,
            },
        },
        {
            title: 'Training',
            pageId: `${PageName.TRAINING}_${PageName.MANUAL}_${PageName.INFO}`,
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.CONCEPT}/${RoutingSegment.TRAINING_MANAGEMENT}/${RoutingSegment.INFO}`,
            categories: [RoutingSegment.TRAINING_MANAGEMENT],
            module: CurafidaFeature.training,
            role: [UserRoles.MANAGE_THERAPY],
            showTitle: true,
            configuration: {
                showPageTitle: true,
            },
        },
        {
            title: 'Training',
            pageId: `${PageName.TRAINING}_${PageName.MANUAL}_${PageName.INFO}`,
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.CONCEPT}/${RoutingSegment.TRAINING_MANAGEMENT}/${RoutingSegment.INFO}`,
            categories: [RoutingSegment.TRAINING_MANAGEMENT],
            module: CurafidaFeature.training,
            role: [UserRoles.MANAGE_THERAPY],
            showTitle: true,
            configuration: {
                showPageTitle: true,
            },
        },

        {
            title: 'Messung starten',
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.PATIENT_MANAGEMENT}/${RoutingSegment.MEASUREMENT_MANAGEMENT}/${RoutingSegment.EXPERT_MODE}`,
            pageId: PageName.MEASUREMENT_EXPERT,
            role: [UserRoles.PATIENT],
            categories: [RoutingSegment.MEASUREMENT],
            module: CurafidaFeature.measurement,
            configuration: {
                menuVisibility: false,
                headerVisibility: false,
                showPageTitle: true,
                hasHelpIcon: true,
            },
        },
        {
            title: 'Messung durchführen',
            url: `/${RoutingSegment.MEMBER}/guided-measurement`,
            pageId: 'guided-measurement',
            role: [UserRoles.PATIENT],
            categories: [RoutingSegment.MEASUREMENT],
            module: CurafidaFeature.measurement,
            configuration: {
                menuVisibility: false,
                headerVisibility: false,
                showPageTitle: true,
            },
        },
        {
            title: 'Messungen',
            pageId: PageName.MEASUREMENT_DETAIL,
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.PATIENT_MANAGEMENT}/${RoutingSegment.MEASUREMENT_MANAGEMENT}/${RoutingSegment.DETAIL}`,
            categories: [RoutingSegment.PATIENT_MANAGEMENT],
            module: CurafidaFeature.measurement,
            role: [UserRoles.READ_MEASUREMENT, UserRoles.PATIENT, UserRoles.SUPERVISOR],
            configuration: {
                showPageTitle: true,
            },
        },

        {
            title: 'Normbereiche einstellen',
            url:
                '/' +
                RoutingSegment.MEMBER +
                '/' +
                RoutingSegment.PATIENT_MANAGEMENT +
                '/' +
                RoutingSegment.MEASUREMENT_MANAGEMENT +
                '/' +
                RoutingSegment.NORM_VALUES,
            pageId: PageName.NORM_VALUES,
            role: [UserRoles.CREATE_MEASUREMENT],
            categories: [RoutingSegment.MEASUREMENT],
            module: CurafidaFeature.measurement,
            configuration: {
                menuVisibility: false,
                headerVisibility: false,
                showPageTitle: true,
                hasHelpIcon: false,
            },
        },
        {
            title: 'ECG',
            pageId: 'ecg',
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.PATIENT_MANAGEMENT}/${RoutingSegment.MEASUREMENT_MANAGEMENT}/${RoutingSegment.ECG}`,
            categories: [RoutingSegment.PATIENT_MANAGEMENT],
            module: CurafidaFeature.measurement,
            role: [UserRoles.MANAGE_MEASUREMENT, UserRoles.PATIENT],
            configuration: {
                menuVisibility: false,
                headerVisibility: false,
                showPageTitle: true,
            },
        },
        {
            title: 'Konsil',
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.CONSULTATION_MANAGEMENT}/new`,
            categories: [RoutingSegment.PATIENT_MANAGEMENT],
            module: CurafidaFeature.consultation,
            role: [UserRoles.MANAGE_CONSULTATION],
            configuration: {
                showPageTitle: true,
            },
        },
        {
            title: 'Konsil',
            url:
                `/${RoutingSegment.MEMBER}/${RoutingSegment.PATIENT_MANAGEMENT}/` +
                `${RoutingSegment.CONSULTATION_MANAGEMENT}`,
            categories: [RoutingSegment.PATIENT_MANAGEMENT],
            module: CurafidaFeature.consultation,
            role: [UserRoles.READ_CONSULTATION],
            configuration: {
                showPageTitle: true,
            },
        },
        {
            title: 'Auftrag',
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.ORDER_MANAGEMENT}/new`,
            categories: [RoutingSegment.PATIENT_MANAGEMENT],
            module: CurafidaFeature.order,
            role: [UserRoles.MANAGE_ORDER],
            configuration: {
                showPageTitle: true,
            },
        },
        {
            title: 'Auftrag',
            url:
                `/${RoutingSegment.MEMBER}/${RoutingSegment.PATIENT_MANAGEMENT}/` +
                `${RoutingSegment.ORDER_MANAGEMENT}`,
            categories: [RoutingSegment.PATIENT_MANAGEMENT],
            module: CurafidaFeature.order,
            role: [UserRoles.READ_ORDER],
            configuration: {
                showPageTitle: true,
            },
        },
        {
            title: 'Screening',
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.SCREENING_MANAGEMENT}/new`,
            categories: [RoutingSegment.PATIENT_MANAGEMENT],
            module: CurafidaFeature.screening,
            //TODO: Check correct rights for the page #3993
            //// https://gitlab.ztm-badkissingen.de/curafida/development/ionic-common/-/issues/6234
            role: [UserRoles.write_medical_patientdata, UserRoles.CREATE_SUPERVISED_PATIENT],
            configuration: {
                showPageTitle: true,
            },
        },
        //TODO: Remove when the new patient app works good
        {
            title: 'Aufgaben',
            pageId: PageName.PATIENT_TASK,
            url:
                `/${RoutingSegment.MEMBER}/${RoutingSegment.PATIENT_MANAGEMENT}/` +
                `${RoutingSegment.TASK_MANAGEMENT}/${RoutingSegment.TASKS}/${RoutingSegment.LIST}`,
            categories: [RoutingSegment.TASKS],
            module: CurafidaFeature.task,
            role: [UserRoles.PATIENT],
            configuration: {
                showPageTitle: false,
                menuVisibility: false,
            },
        },

        {
            title: 'Medikation',
            pageId: PageName.MEDICATION,
            url:
                `/${RoutingSegment.MEMBER}/${RoutingSegment.PATIENT_MANAGEMENT}/` +
                `${RoutingSegment.MEDICATION_MANAGEMENT}/${RoutingSegment.DETAIL}`,
            categories: [RoutingSegment.PATIENT_MANAGEMENT],
            module: CurafidaFeature.medication,
            role: [UserRoles.PATIENT, UserRoles.READ_MEDICATION, UserRoles.SUPERVISOR],
            configuration: {
                showPageTitle: true,
            },
        },
        {
            title: 'Notiz',
            pageId: PageName.NOTE,
            url:
                `/${RoutingSegment.MEMBER}/${RoutingSegment.PATIENT_MANAGEMENT}/` +
                `${RoutingSegment.NOTE_MANAGEMENT}/${RoutingSegment.DETAIL}`,
            categories: [RoutingSegment.PATIENT_MANAGEMENT],
            module: CurafidaFeature.note,
            role: [UserRoles.PATIENT, UserRoles.read_note, UserRoles.SUPERVISOR],
            configuration: {
                showPageTitle: true,
            },
        },
        {
            title: 'Mobilität',
            pageId: PageName.MOBILITY,
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.PATIENT_MANAGEMENT}/${RoutingSegment.MOBILITY}`,
            categories: [RoutingSegment.MOBILITY],
            module: CurafidaFeature.mobility,
            role: [UserRoles.PATIENT, UserRoles.SUPERVISOR, UserRoles.MANAGE_THERAPY],
            configuration: {},
        },
        {
            title: 'Lernen',
            pageId: PageName.LEARNING,
            url:
                `/${RoutingSegment.MEMBER}/${RoutingSegment.PATIENT_MANAGEMENT}/` +
                `${RoutingSegment.LEARNING_MANAGEMENT}/${RoutingSegment.LIST}`,
            categories: [RoutingSegment.LEARNING_MANAGEMENT],
            module: CurafidaFeature.learning,
            role: [UserRoles.PATIENT],
            configuration: {
                showPageTitle: false,
                menuVisibility: false,
            },
        },
        //TODO : Check if roles can be removed
        {
            title: 'Profil',
            pageId: PageName.PROFILE,
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.PROFILE}`,
            categories: [RoutingSegment.PROFILE],
            module: CurafidaFeature.common,
            role: [
                UserRoles.PATIENT,
                UserRoles.CAREGIVER,
                UserRoles.USER_MANAGER,
                UserRoles.CATALOG_MANAGER,
                UserRoles.PHYSICIAN,
                UserRoles.ADMIN,
                UserRoles.SUPERVISOR,
                UserRoles.ANALYST,
            ],
            configuration: {
                menuVisibility: true,
                showPageTitle: true,
                hasEmail: true,
                // isEmailObligatory deprecated
                isEmailObligatory: true,
                hasGender: true,
                hasBirthdate: true,
                hasPhone: true,
                showUsername: true,
                hasUserTitle: true,
            },
        },
    ];
}

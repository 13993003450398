import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class RedirectGuard {
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        window.open(route.data['externalUrl'], '_blank');
        return true;
    }
}

<ion-datetime
    #dateTime
    (ionChange)="saveDate(dateTime.value)"
    [max]="maxDate"
    [min]="minDate"
    [minuteValues]="minuteValues"
    [presentation]="presentation"
    [value]="date"
    firstDayOfWeek="1"
    size="cover"
    show-default-title="false"
    locale="de-DE">
    <div slot="title">{{'SELECT_DATE'|translate}}</div>
    <div slot="time-label">{{'Uhrzeit'|translate}}</div>
</ion-datetime>
<ion-buttons class="flex-container-row justify-content-space-between ion-padding">
    <ion-button color="danger" class="btn-font-medium" fill="solid"
                (click)="dismissPopOver()">{{'CANCEL' | translate}}</ion-button>
    <ion-button color="primary" class="btn-font-medium" fill="solid"
                (click)="sendDate()">{{'Bestätigen' | translate}}</ion-button>
</ion-buttons>

<ion-toolbar color="primary" style="padding-left: 1em">
    <div slot="start">{{title | translate}}</div>
    <ion-button class="text-align-center toolbar-button" slot="end" color="primary-inverse" [fill]="'outline'" *ngIf="hasSecondaryButton"
                (click)="secondaryButtonClicked()">{{secondaryButtonLabel}}</ion-button>
    <ion-button class="text-align-center toolbar-button" slot="end" color="primary-inverse" [fill]="'outline'" *ngIf="hasActionButton"
                (click)="actionButtonClicked()">{{actionButtonLabel}}</ion-button>
    <ion-button slot="end" (click)="dismiss()" fill="outline">
        <ion-icon name="close" style="color: var(--ion-color-primary-contrast);"></ion-icon>
    </ion-button>
</ion-toolbar>

import { Component, EventEmitter, Input, OnInit, Optional, Output, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { CurafidaInputComponent } from '../curafida-input';
import { ModalController, PopoverController } from '@ionic/angular';
import { DateTimePickerComponent } from '../../date-picker/date-time-picker/date-time-picker.component';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'curafida-date-input',
    templateUrl: './curafida-date-input.component.html',
    styleUrls: ['./curafida-date-input.component.scss'],
})
export class CurafidaDateInputComponent extends CurafidaInputComponent implements OnInit {
    @Input()
    dateFormat = 'dd.MM.yyyy';

    @Input()
    disabledPastDays = false;

    @Input()
    disabledFutureDays = false;

    @Input()
    borderColorPrimary = false;

    @Input()
    isEditEnabled = true;

    @Output()
    inputBlur: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input()
    minuteStep?: number;

    @Input()
    presentation?: string;

    @Input()
    min?: string;
    @Input()
    max?: string;

    get formattedValue(): string {
        if (this.formGroup.controls[this.formControlName]?.value && this.dateFormat === 'dd.MM.yyyy H:mm') {
            return (
                this.datePipe.transform(
                    this.formGroup.controls[this.formControlName]?.value,
                    this.dateFormat,
                    'de',
                    'de',
                ) + ' Uhr'
            );
        }
        return this.datePipe.transform(
            this.formGroup.controls[this.formControlName].value,
            this.dateFormat,
            'de',
            'de',
        );
    }

    constructor(
        protected modalCtrl: ModalController,
        private popoverController: PopoverController,
        @Self()
        @Optional()
        public ngControl: NgControl,
        private datePipe: DatePipe,
    ) {
        super(ngControl);
    }

    async openDatePicker() {
        if (!this.formGroup.controls[this.formControlName].disabled) {
            if (this.formControlName) {
                const date =
                    this.formGroup &&
                    this.formGroup.controls[this.formControlName].value != null &&
                    this.formGroup.controls[this.formControlName].value != undefined &&
                    this.formGroup.controls[this.formControlName].value != ''
                        ? new Date(this.formGroup.controls[this.formControlName].value).toISOString()
                        : new Date().toISOString();
                const popoverElement = await this.popoverController.create({
                    component: DateTimePickerComponent,
                    cssClass: 'modal-date-picker',
                    componentProps: {
                        date,
                        minDate: this.min,
                        maxDate: this.max,
                        disableFutureDays: this.disabledFutureDays,
                        disablePastDays: this.disabledPastDays,
                        minuteStep: this.minuteStep,
                        presentation: this.presentation,
                    },
                });
                await popoverElement.present();
                await popoverElement.onDidDismiss().then((pickedDate) => {
                    if (pickedDate !== null && pickedDate) {
                        if (pickedDate.data) {
                            this.formGroup.controls[this.formControlName].setValue(pickedDate.data);
                            this.formGroup.controls[this.formControlName].markAsDirty();
                            this.inputBlur.emit(true);
                        }
                    }
                });
            }
        }
    }

    async ngOnInit() {
        await super.ngOnInit();
    }
}

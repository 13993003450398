<div class="flex-container-column full-width justify-content-space-between" (click)="selectChart($event)">
    <div class="flex-container-column full-width" *ngFor="let canvas of canvasListHtmlElement">
        <div class="flex-container-row align-items-center full-width ion-padding-top">
            <div style="margin-right: 1%; column-width: 1em">{{canvas.id | convertNumber2ECGNomenclaturePipe }}</div>
            <canvas id="{{canvas.id}}" #canvas [width]="canvas.width" [height]="canvas.height"></canvas>
        </div>
    </div>
    <!--<div class="flex-container-column" style="width: 33%">
        <div class="flex-container-row align-items-center full-width ion-padding-top">
            <div class="ion-margin-horizontal">I</div>
            <canvas id="I" #canvas [width]="canvasWidth" [height]="canvasHeight"></canvas>
        </div>
        <div class="flex-container-row align-items-center full-width ion-padding-top">
            <div class="ion-margin-horizontal">II</div>
            <canvas id="II" #canvas [width]="canvasWidth" [height]="canvasHeight"></canvas>
        </div>
        <div class="flex-container-row align-items-center full-width ion-padding-top">
            <div class="ion-margin-horizontal">III</div>
            <canvas id="III" #canvas [width]="canvasWidth" [height]="canvasHeight"></canvas>
        </div>
        <div class="flex-container-row align-items-center full-width ion-padding-top">
            <div class="ion-margin-horizontal">aVR</div>
            <canvas id="aVR" #canvas [width]="canvasWidth" [height]="canvasHeight"></canvas>
        </div>
    </div>
    <div class="flex-container-column" style="width: 33%">
        <div class="flex-container-row align-items-center full-width ion-padding-top">
            <div class="ion-margin-horizontal">aVF</div>
            <canvas id="aVF" #canvas [width]="canvasWidth" [height]="canvasHeight"></canvas>
        </div>
        <div class="flex-container-row align-items-center full-width ion-padding-top">
            <div class="ion-margin-horizontal">aVL</div>
            <canvas id="aVL" #canvas [width]="canvasWidth" [height]="canvasHeight"></canvas>
        </div>
        <div class="flex-container-row align-items-center full-width ion-padding-top">
            <div class="ion-margin-horizontal">V1</div>
            <canvas id="V1" #canvas [width]="canvasWidth" [height]="canvasHeight"></canvas>
        </div>
        <div class="flex-container-row align-items-center full-width ion-padding-top">
            <div class="ion-margin-horizontal">V2</div>
            <canvas id="V2" #canvas [width]="canvasWidth" [height]="canvasHeight"></canvas>
        </div>
    </div>
    <div class="flex-container-column full-height" style="width: 33%">
        <div class="flex-container-row align-items-center full-width ion-padding-top">
            <div class="ion-margin-horizontal">V3</div>
            <canvas id="V3" #canvas [width]="canvasWidth" [height]="canvasHeight"></canvas>
        </div>
        <div class="flex-container-row align-items-center full-width ion-padding-top">
            <div class="ion-margin-horizontal">V4</div>
            <canvas id="V4" #canvas [width]="canvasWidth" [height]="canvasHeight"></canvas>
        </div>
        <div class="flex-container-row align-items-center full-width ion-padding-top">
            <div class="ion-margin-horizontal">V5</div>
            <canvas id="V5" #canvas [width]="canvasWidth" [height]="canvasHeight"></canvas>
        </div>
        <div class="flex-container-row align-items-center full-width ion-padding-top">
            <div class="ion-margin-horizontal">V6</div>
            <canvas id="V6" #canvas [width]="canvasWidth" [height]="canvasHeight"></canvas>
        </div>
    </div>-->
</div>

<div (click)='openDatePicker()' class="flex-container-row full-width text-standard-input-size" *ngIf='formGroup && formControlName' [formGroup]='formGroup '
    [ngClass]="{'pointer':formGroup.controls[formControlName].status !== 'DISABLED'}" [style.pointer-events]="isEditEnabled ? 'all' : 'none'">
    <ion-label *ngIf='!isMobile '
               [ngClass]="{'ion-label-color-disabled': formGroup.controls[formControlName].status === 'DISABLED',
               'ion-label-color-enabled': !formGroup.disabled}"
               class='item-label-user-info self-align-center ion-padding-top word-breaking'>{{label}}</ion-label>
    <ion-item
        *ngIf='!isMobile'
        [ngClass]="{ 'ion-item-box-enable pointer':formGroup.controls[formControlName].status !== 'DISABLED',
        'ion-item-box-disable':formGroup.controls[formControlName].status === 'DISABLED',
        'border-color-primary': borderColorPrimary }"
        class='item-input-width ion-item-box text-standard-input-size'>
        <ion-input [disabled]='true'>{{formattedValue}}</ion-input>
        <ion-button *ngIf="isEditEnabled" [disabled]='formGroup.controls[formControlName].disabled' id='nested-button' (click)='openDatePicker()'>
            <ion-icon name='calendar-outline'></ion-icon>
        </ion-button>
    </ion-item>
    <ion-item (click)='openDatePicker()' *ngIf='isMobile'
              [ngClass]="{ 'ion-item-box-enable':formGroup.controls[formControlName].status !== 'DISABLED',
              'ion-item-box-disable':formGroup.controls[formControlName].status === 'DISABLED',
              'border-color-primary': borderColorPrimary }"
              [style.pointer-events]="isEditEnabled ? 'all' : 'none'"
              class='item-input-full-width'>
        <ion-label [ngClass]="{'ion-label-color-disabled': formGroup.controls[formControlName].status === 'DISABLED',
                   'ion-label-color-enabled': formGroup.controls[formControlName].status === 'VALID'}"
                   position='stacked'>{{label}}</ion-label>
        <ion-input [disabled]='true'>{{formattedValue}}</ion-input>
    </ion-item>
</div>
<div *ngIf='formGroup' [formGroup]='formGroup'>
    <error-message *ngIf="formGroup && formControlName && formErrors" [errorMessageInput]="formErrors" [formControlName]="formControlName"
                   [formGroup]="formGroup"></error-message>
</div>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Therapy, TherapyState, TherapyWithTherapySessions } from '../../entities/therapy';
import { ApiService } from '../../../api';
import { LoadingService } from '../../../common/services/loading/loading.service';
import { PaginatedResponse, SortBy, SortOrder } from '../../../common/entities/paginated-response';
import { TherapyGoal } from '../../entities/therapy-goal/therapy-goal';
import { ExerciseType } from '../../entities/exerciseSession';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { isAfter, isBefore, startOfWeek } from 'date-fns';
import { TherapySession, TherapySessionState } from '../../entities/therapy-session/therapy-session';

export interface GetUserTherapiesArguments {
    username: string;
    therapyState?: TherapyState;
    offset?: number;
    limit?: number;
    filter?: string;
    sortOrder?: SortOrder;
    sortBy?: SortBy;
    therapyGoal?: TherapyGoal;
    exerciseType?: ExerciseType;
    includeTherapyChangeEvents?: boolean; // defaults to "false" in getUserTherapies
    includeExercises?: boolean; // defaults to "true" in getUserTherapies
    startDate?: string;
    endDate?: string;
}

@Injectable({
    providedIn: 'root',
})
export class UsersTherapiesService {
    readonly userTherapiesWithTherapySessions$ = new BehaviorSubject<PaginatedResponse<TherapyWithTherapySessions[]>>(
        new PaginatedResponse<TherapyWithTherapySessions[]>(),
    );

    constructor(protected http: HttpClient, private loadingService: LoadingService) {}

    async getUserTherapies(args: GetUserTherapiesArguments): Promise<PaginatedResponse<Therapy[]>> {
        if (args.includeExercises !== false) args.includeExercises = true;
        const url = new URL(`${ApiService.url}users/${args.username}/therapiesOfUser`);

        // build query param string
        if (args.offset) url.searchParams.set('offset', args.offset.toString());
        if (args.limit) url.searchParams.set('limit', args.limit.toString());
        if (args.filter) url.searchParams.set('filter', args.filter);
        if (args.sortBy) url.searchParams.set('sortBy', args.sortBy);
        if (args.sortOrder) url.searchParams.set('sortOrder', args.sortOrder);
        if (args.therapyGoal) url.searchParams.set('therapyGoal', args.therapyGoal);
        if (args.exerciseType) url.searchParams.set('exerciseType', args.exerciseType);
        if (args.therapyState) url.searchParams.set('therapyState', args.therapyState);
        if (args.includeTherapyChangeEvents) url.searchParams.set('includeTherapyChangeEvents', 'true');
        // because this currently defaults to true, only false needs to be sent explicitly
        if (!args.includeExercises) url.searchParams.set('includeExercises', args.includeExercises.toString());

        return this.http.get<PaginatedResponse<Therapy[]>>(url.toString(), ApiService.options).toPromise();
    }

    initUserTherapiesWithTherapySessions(args: GetUserTherapiesArguments): void {
        if (args.includeExercises !== false) args.includeExercises = true;
        const url = new URL(`${ApiService.url}users/${args.username}/therapiesOfUser`);

        // build query param string
        if (args.offset) url.searchParams.set('offset', args.offset.toString());
        if (args.limit) url.searchParams.set('limit', args.limit.toString());
        if (args.filter) url.searchParams.set('filter', args.filter);
        if (args.sortBy) url.searchParams.set('sortBy', args.sortBy);
        if (args.sortOrder) url.searchParams.set('sortOrder', args.sortOrder);
        if (args.therapyGoal) url.searchParams.set('therapyGoal', args.therapyGoal);
        if (args.exerciseType) url.searchParams.set('exerciseType', args.exerciseType);
        if (args.therapyState) url.searchParams.set('therapyState', args.therapyState);
        if (args.includeTherapyChangeEvents) url.searchParams.set('includeTherapyChangeEvents', 'true');
        // because this currently defaults to true, only false needs to be sent explicitly
        if (!args.includeExercises) url.searchParams.set('includeExercises', args.includeExercises.toString());
        this.http
            .get<PaginatedResponse<Therapy[]>>(url.toString(), ApiService.options)
            .pipe(
                tap((i) => {
                    if (i.total === 0) {
                        this.userTherapiesWithTherapySessions$.next(
                            new PaginatedResponse<TherapyWithTherapySessions[]>(),
                        );
                    }
                }),
                mergeMap((it) => {
                    it.items = it.items.filter((i) =>
                        isAfter(new Date(i.endDate), startOfWeek(new Date(args.endDate), { weekStartsOn: 1 })),
                    );
                    return forkJoin([
                        ...it.items.map((therapy: TherapyWithTherapySessions) => {
                            const url = new URL(
                                `${ApiService.url}users/${args.username}/therapies/${therapy.id}/therapySessions`,
                            );
                            url.searchParams.set('offset', '0');
                            url.searchParams.set('limit', therapy.therapySessionsPerWeek.toString());
                            url.searchParams.set('state', TherapySessionState.RUNNING);
                            const runningTherapySession$ = this.http.get<PaginatedResponse<TherapySession[]>>(
                                url.toString(),
                                ApiService.options,
                            );

                            url.searchParams.set('state', TherapySessionState.COMPLETED);
                            const completedTherapySessions$ = this.http.get<PaginatedResponse<TherapySession[]>>(
                                url.toString(),
                                ApiService.options,
                            );

                            return forkJoin([runningTherapySession$, completedTherapySessions$]).pipe(
                                map((item) => {
                                    if (args.startDate && args.endDate) {
                                        item[0].items = item[0].items.filter((i) => {
                                            isBefore(new Date(i.earliestStart), new Date(args.endDate)) &&
                                                isBefore(new Date(i.latestEnd), new Date(args.endDate)) &&
                                                isAfter(new Date(i.earliestStart), new Date(args.startDate)) &&
                                                isAfter(new Date(i.latestEnd), new Date(args.startDate));
                                        });
                                        item[0].count = item[0].items.length;
                                        therapy.runningTherapySession = item[0];
                                        item[1].items = item[1].items.filter((i) => {
                                            isBefore(new Date(i.earliestStart), new Date(args.endDate)) &&
                                                isBefore(new Date(i.latestEnd), new Date(args.endDate)) &&
                                                isAfter(new Date(i.earliestStart), new Date(args.startDate)) &&
                                                isAfter(new Date(i.latestEnd), new Date(args.startDate));
                                        });
                                        item[1].count = item[1].items.length;
                                        therapy.completeTherapySession = item[1];
                                    } else {
                                        therapy.runningTherapySession = item[0];
                                        therapy.completeTherapySession = item[1];
                                    }
                                    return therapy;
                                }),
                            );
                        }),
                    ]).pipe(
                        map((items) => {
                            it.items = items;
                            return it as PaginatedResponse<TherapyWithTherapySessions[]>;
                        }),
                    );
                }),
            )
            .subscribe((result: PaginatedResponse<TherapyWithTherapySessions[]>) => {
                this.userTherapiesWithTherapySessions$.next(result);
            });
    }

    async getUserTherapy(
        username: string,
        therapyId: number,
        includeTags = false,
        includeChildTherapies = false,
    ): Promise<Therapy> {
        const url = new URL(`${ApiService.url}users/${username}/therapies/${therapyId}`);
        if (includeTags) url.searchParams.set('includeTags', String(includeTags));
        if (includeChildTherapies) url.searchParams.set('includeChildTherapies', String(includeChildTherapies));

        return this.http.get<Therapy>(url.toString(), ApiService.options).toPromise();
    }

    async assignTherapyToUser(username: string, therapyId: number): Promise<Therapy> {
        const url = `${ApiService.url}users/${username}/therapies/${therapyId}`;
        return this.http.put<Therapy>(url, '', ApiService.options).toPromise();
    }

    async removeTherapyUser(username: string, therapyId: number): Promise<any> {
        this.loadingService.startLoadingModal('Plan wird entfernt.');
        return this.http
            .delete(`${ApiService.url}users/${username}/therapies/${therapyId}`, ApiService.options)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }
}

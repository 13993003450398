import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'convertNumber2ECGNomenclaturePipe',
})
export class ConvertNumber2ECGNomenclaturePipePipe implements PipeTransform {
    // TODO: implement a logic codig to convert the number.
    transform(value: string, ...args: any[]): string {
        if (value === '1') {
            return 'I';
        } else if (value === '2') {
            return 'II';
        } else if (value === '3') {
            return 'III';
        } else if (value === '4') {
            return 'aVR';
        } else if (value === '5') {
            return 'aVF';
        } else if (value === '6') {
            return 'aVL';
        }
        return value
    }
}

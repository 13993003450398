<ion-header style="padding-top: 0!important;">
    <ion-toolbar class="flex-container-row align-items-center" color="primary">
        <ion-title slot="start">{{title | translate}}</ion-title>
        <ion-button (click)="dismissModal()" fill="outline" slot="end">
            <ion-icon name="close" style="color: #ffffff;"></ion-icon>
        </ion-button>
    </ion-toolbar>
</ion-header>
<ion-content>
    <lib-tutorial-slider #tutorialSliderComponent [slideshow]="tutorialDefinition"></lib-tutorial-slider>
</ion-content>
<ion-footer class="flex-container-row justify-content-space-between ion-no-border modal-buttons-position-2">
    <ion-button (click)="backStep()" class="btn-font-medium" disabled="{{tutorialSliderComponent.isBeginning}}">
        Zurück
    </ion-button>
    <ion-button (click)="fowardStep()" class="btn-font-medium">
        <span *ngIf="tutorialSliderComponent.isEnd">Fertig</span>
        <span *ngIf="!tutorialSliderComponent.isEnd">Weiter</span>
    </ion-button>
</ion-footer>

<div *ngIf="formGroup && formControlName" [formGroup]="formGroup" class="flex-container-row full-width text-standard-input-size">
    <ion-label *ngIf="!isMobile" [ngClass]="{
                   'ion-label-color-disabled': ngControl.disabled,
                   'ion-label-color-enabled': !ngControl.disabled}"
               class="item-label-user-info self-align-center ion-padding-top word-breaking">{{label}}
        <ion-icon *ngIf="labelIcon !== ''" class="label-icon" color="{{labelIconColor}}" name="{{labelIcon}}">
        </ion-icon>
    </ion-label>
    <ion-label *ngIf="!isMobile && explanation">{{explanation}}</ion-label>
    <ion-item [lines]='"none"' [ngClass]="{
            'item-input-width ion-item-box': !isMobile,
            'item-input-full-width': isMobile,
            'ion-item-box-enable':!ngControl.disabled,
            'ion-item-box-disable':ngControl.disabled,
            'border-color-primary': borderColorPrimary }" class="text-standard-input-size">
        <ion-label *ngIf="isMobile" [ngClass]="{'ion-label-color-disabled': ngControl?.disabled,
                   'ion-label-color-enabled': !ngControl?.disabled}"
                   position="floating">{{label}}<span
            *ngIf="unit"> ({{unit}})</span></ion-label>
        <ion-input (ionChange)='changeValue($event)' [disabled]='formGroup.controls[formControlName]?.disabled' [inputmode]="inputMode" [type]="inputType" [value]="formGroup.controls[formControlName].value"></ion-input>
        <ion-label *ngIf="!isMobile" class="ion-label-color-enabled"> {{unit}}</ion-label>
    </ion-item>
</div>
<div *ngIf='formGroup' [formGroup]='formGroup'>
    <error-message *ngIf="formGroup && formControlName && formErrors" [errorMessageInput]="formErrors" [formControlName]="formControlName"
                   [formGroup]="formGroup"></error-message>
</div>

import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { Network } from '@ionic-native/network/ngx';
import { Platform, ToastController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../../api';
import { ToastService } from '../../../common/services/toast-service/toast-service.service';
import { IonicColor } from '../../../common/entities/toast/ionic-color';

export enum ConnectionStatus {
    NONE,
    UNKNOWN,
    BACKEND_REACHABLE,
    BACKEND_UNREACHABLE,
    ONLINE,
    OFFLINE,
}

@Injectable({
    providedIn: 'root',
})
export class NetworkService {
    private onlineStatus: BehaviorSubject<ConnectionStatus> = new BehaviorSubject(ConnectionStatus.UNKNOWN);
    private backendReachableStatus: BehaviorSubject<ConnectionStatus> = new BehaviorSubject(ConnectionStatus.UNKNOWN);

    constructor(
        private network: Network,
        private toastController: ToastController,
        private plt: Platform,
        protected http: HttpClient,
        public toastService: ToastService,
    ) {
        this.plt.ready().then(() => {
            if (this.plt.is('cordova')) {
                this.initializeNetworkEventsForCordova();
            } else {
                this.initializeNetworkEventsForBrowser();
            }
            const onlineStatus = this.network.type !== 'none' ? ConnectionStatus.ONLINE : ConnectionStatus.OFFLINE;
            this.onlineStatus.next(onlineStatus);
        });
    }

    public initializeNetworkEventsForCordova() {
        this.network.onDisconnect().subscribe(() => {
            // // console.log('WE ARE OFFLINE');
            this.updateNetworkStatus(ConnectionStatus.OFFLINE);
        });
        this.network.onConnect().subscribe(() => {
            // // // console.log('WE ARE ONLINE');
            this.updateNetworkStatus(ConnectionStatus.ONLINE);
            // this.updateBackendReachableStatus();
        });
    }

    public onNetworkChange(): Observable<ConnectionStatus> {
        return this.onlineStatus.asObservable();
    }

    public getCurrentNetworkStatus(): ConnectionStatus {
        return this.onlineStatus.getValue();
    }

    public getCurrentNetworkType() {
        return this.network.type;
    }

    public isInternetConnectionAvailable() {
        if (
            this.network.type === this.network.Connection.NONE ||
            this.network.type === this.network.Connection.UNKNOWN
        ) {
            return false;
        }
        return true;
    }

    // TODO: It should also be proved
    async updateBackendReachableStatus(): Promise<ConnectionStatus> {
        try {
            const response = await this.http
                .get(`${ApiService.url}`, {
                    responseType: 'text',
                })
                .toPromise();
            if (response.toLowerCase() === 'mona' || response.toLowerCase() === 'mia') {
                this.backendReachableStatus.next(ConnectionStatus.BACKEND_REACHABLE);
            } else {
                this.backendReachableStatus.next(ConnectionStatus.BACKEND_UNREACHABLE);
            }
        } catch (error) {
            this.backendReachableStatus.next(ConnectionStatus.BACKEND_UNREACHABLE);
        }
        return this.backendReachableStatus.getValue();
    }

    private initializeNetworkEventsForBrowser() {
        fromEvent(window, 'offline').subscribe(() => {
            // // console.log('OFFLINE BROWSER');
            this.updateNetworkStatus(ConnectionStatus.OFFLINE);
        });
        fromEvent(window, 'online').subscribe(() => {
            // // // console.log('ONLINE BROWSER');
            this.updateNetworkStatus(ConnectionStatus.ONLINE);
            // this.updateBackendReachableStatus();
        });
    }

    private async updateNetworkStatus(status: ConnectionStatus) {
        this.onlineStatus.next(status);
        const connection = status === ConnectionStatus.OFFLINE ? 'Offline' : 'Online';
        if (connection === 'Offline') {
            await this.toastService.showToast(
                `Sie befinden sich aktuell im ${connection}-Modus.`,
                IonicColor.medium,
                4000,
            );
            // message.text = `Sie befinden sich aktuell im ${connection}-Modus`;
        }
    }
}

import {
    Component,
    DoCheck,
    EventEmitter,
    Input,
    KeyValueDiffer,
    KeyValueDiffers,
    OnDestroy,
    OnInit,
    Output,
    Type,
} from '@angular/core';
import {
    ActionEmitter,
    ActionType,
    ItemAdapterComponent,
    ItemType,
    TableConfig,
    TableItem,
    TableUpdateValue,
} from '../../entities/table';
import { PaginatedResponse, SortBy, SortOrder } from '../../../common/entities/paginated-response';
import { ItemReorderEventDetail } from '@ionic/core';
import { Content } from '../../../therapy/entities/content';
import { Subscription } from 'rxjs';
import { Platform } from '@ionic/angular';
import { StyleService } from '../../../common/services/style/style.service';

@Component({
    selector: 'curafida-table',
    templateUrl: './curafida-table.component.html',
    styleUrls: ['./curafida-table.component.scss'],
})
export class CurafidaTableComponent<T> implements OnInit, OnDestroy, DoCheck {
    showTable = false;
    selected = [];
    paginationList: number[] = [];
    startPaginationToShowList: number[] = [];
    endPaginationToShowList: number[] = [];
    Content = Content;
    isMobile = false;
    platformSubscription: Subscription;
    @Input()
    hasPagination = false;
    @Input()
    isLoadingSuccess = true;
    // output option to open the item
    @Output()
    openDetail = new EventEmitter();
    // output option when a checkbox is clicked
    @Output()
    checkboxAction = new EventEmitter();
    // output action to update the table
    @Output()
    updateTable = new EventEmitter<TableUpdateValue>();
    @Output()
    setActionOnItem = new EventEmitter<ActionEmitter<T>>();
    @Output()
    sort = new EventEmitter<{ sortBy: SortBy | string; sortOrder?: SortOrder }>();
    @Output()
    activate = new EventEmitter();
    @Output()
    updateContentOfTable = new EventEmitter<PaginatedResponse<T[]>>();
    @Output()
    isReorderUpdate = new EventEmitter<boolean>();

    @Input()
    mobileAdapter: Type<ItemAdapterComponent>;
    ItemType = ItemType;
    SortOrder = SortOrder;
    mobileList: PaginatedResponse<T[]> = new PaginatedResponse<T[]>();
    notes: TableItem;
    // Table Configuration
    ActionType = ActionType;
    private differ: KeyValueDiffer<string, any>;

    constructor(private platform: Platform, private styleService: StyleService, private differs: KeyValueDiffers) {
        this.differ = this.differs.find({}).create();
    }

    _listTableConfig: TableConfig<T[]>;

    @Input()
    set listTableConfig(value: TableConfig<T[]>) {
        this._listTableConfig = value;
        this.mobileList = value.list;
        this.initTable();
    }

    ngOnInit(): void {
        this.initTable();
        this.platformSubscription = this.platform.resize.subscribe(() => {
            this.initTable();
        });
        this.showTable = true;
    }

    organizeDescendingTableItem(table: TableItem[]): TableItem[] {
        if (table)
            table.sort((a, b) => {
                if (new Date(a.sortOrderWeb).getTime() < new Date(b.sortOrderWeb).getTime()) {
                    return -1;
                } else if (new Date(a.sortOrderWeb).getTime() > new Date(b.sortOrderWeb).getTime()) {
                    return 1;
                }
                // a must be equal to b
                return 0;
            });
        return table;
    }

    organizeDescendingMobileTableItem(table: TableItem[]): TableItem[] {
        if (table)
            table.sort((a, b) => {
                if (new Date(a.sortOrderMobile).getTime() < new Date(b.sortOrderMobile).getTime()) {
                    return -1;
                } else if (new Date(a.sortOrderMobile).getTime() > new Date(b.sortOrderMobile).getTime()) {
                    return 1;
                }
                // a must be equal to b
                return 0;
            });
        return table;
    }

    ngOnDestroy(): void {
        if (this.platformSubscription) this.platformSubscription.unsubscribe();
    }

    openDetailPage(indexElement: number): void {
        if (this._listTableConfig.isOpenDetailEnable)
            this.openDetail.emit(this._listTableConfig.list.items[indexElement]);
    }

    updateList(offset: number, limit: number): void {
        this.updateTable.emit({ offset, limit });
    }

    emitActionItem(event, element, actionType: ActionType, itemSetting: TableItem): void {
        if (event) event.stopPropagation();
        if (actionType === ActionType.OPEN_NEW_PAGE || !actionType) {
            if (this._listTableConfig.isOpenDetailEnable) this.openDetail.emit(element);
        }
        if (actionType === ActionType.DELETE && element.hideDeleteButton) return;
        if (!this.isMobile && element[itemSetting.disabledProp]) return;
        this.setActionOnItem.emit(new ActionEmitter<T>(actionType, element));
    }

    emitActionSelection(value: ActionType, element: T, itemSetting?: TableItem): void {
        if (!this.isMobile) if (element[itemSetting?.disabledProp]) return;
        this.emitActionItem(null, element, value, itemSetting);
    }

    async initPagination(value: TableConfig<T[]>): Promise<void> {
        this.paginationList = [];
        let i;
        const numberOfPage = value.list.total / value.list.limit;
        for (i = 0; i < numberOfPage; i++) {
            this.paginationList.push(i);
        }
        this.startPaginationToShowList = [...this.paginationList];
        this.startPaginationToShowList = this.startPaginationToShowList.splice(0, 5);
        this.endPaginationToShowList = [...this.paginationList];
        this.endPaginationToShowList = this.endPaginationToShowList.splice(this.endPaginationToShowList.length - 5, 5);
    }

    previousPage(): void {
        this.updateTable.emit({
            offset: this._listTableConfig.list.offset / this._listTableConfig.list.limit - 1,
            limit: this._listTableConfig.list.limit,
        });
    }

    nextPage(): void {
        this.updateTable.emit({
            offset: this._listTableConfig.list.offset / this._listTableConfig.list.limit + 1,
            limit: this._listTableConfig.list.limit,
        });
    }

    openTablePage(offset: number): void {
        this.updateTable.emit({ offset, limit: this._listTableConfig.list.limit });
    }

    doReorder(ev: CustomEvent<ItemReorderEventDetail>): void {
        this._listTableConfig.list.items = ev.detail.complete(this._listTableConfig.list.items);
        let index = 0;
        for (const item of this._listTableConfig.list.items) {
            // @ts-ignore
            item.order = index;
            index++;
        }
        this.isReorderUpdate.emit(true);
        this.updateContentOfTable.emit(this._listTableConfig.list);
    }

    changeSortOrder(itemSetting: TableItem): void {
        if (itemSetting.sortBy) {
            for (const column of this._listTableConfig.itemSettings) {
                if (column.header !== itemSetting.header) column.sortOrder = SortOrder.NONE;
            }
            switch (itemSetting.sortOrder) {
                case SortOrder.NONE:
                    itemSetting.sortOrder = SortOrder.ASC;
                    break;
                case SortOrder.ASC:
                    itemSetting.sortOrder = SortOrder.DESC;
                    break;
                case SortOrder.DESC:
                    itemSetting.sortOrder = SortOrder.NONE;
                    break;
                default:
                    itemSetting.sortOrder = SortOrder.ASC;
                    break;
            }
            const sortOrder = itemSetting.sortOrder !== SortOrder.NONE ? itemSetting.sortOrder : null;
            this.sort.emit({ sortBy: itemSetting.sortBy, sortOrder });
        }
    }

    async loadData(event): Promise<void> {
        event.target.complete();
        if (
            this._listTableConfig.hasMobileConfig &&
            this.mobileList.offset + this.mobileList.limit <= this._listTableConfig.list.total
        ) {
            this.updateList(this.mobileList.offset / this._listTableConfig.list.limit + 1, this.mobileList.limit);
        }
    }

    async ngDoCheck(): Promise<void> {
        const change = this.differ.diff(this._listTableConfig.list.items);
        if (change) {
            if (this._listTableConfig.list.offset === 0) {
                this.mobileList = this._listTableConfig.list;
            } else {
                this.mobileList.offset = this._listTableConfig.list.offset;
            }
            if (!this.isMobile) await this.initPagination(this._listTableConfig);
            change.forEachItem((item) => {
                if (
                    this.mobileList &&
                    this.mobileList.count !== 0 &&
                    !this.mobileList.items.includes(item.currentValue)
                ) {
                    this.mobileList.items.push(item.currentValue);
                    this.mobileList.count++;
                }
            });
        }
    }

    private initTable(): void {
        this.isMobile = this.styleService.isMobile();
        if (
            this._listTableConfig &&
            this._listTableConfig.mobileConfig &&
            this._listTableConfig.mobileConfig.forceMobile
        ) {
            this.isMobile = true;
        }
        if (this.isMobile) {
            this._listTableConfig.itemSettings = this.organizeDescendingMobileTableItem(
                this._listTableConfig.itemSettings,
            );
            if (this.hasPagination) this.initPagination(this._listTableConfig);
        } else {
            this._listTableConfig.itemSettings = this.organizeDescendingTableItem(this._listTableConfig.itemSettings);
            this.initPagination(this._listTableConfig);
        }
    }
}

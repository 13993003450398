import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../../../common/services/toast-service/toast-service.service';
import { ModalAlertService } from '../../../../common/services/modal';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Logger, LoggingService } from '../../../../logging/logging.service';
import { ExerciseType } from '../../../entities/exerciseSession';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TherapyExerciseGoal } from '../../../pages/training/training-patient-detail/training-patient-detail.page';
import { User } from '../../../../auth/entities/user';
import { UsersService } from '../../../../user/services/user';
import { UsersExerciseGoalsService } from '../../../services/user-exercise-goal';
import { TherapiesService } from '../../../services/therapies';
import { UsersTherapiesService } from '../../../services/users-therapies';
import { UserExerciseSessionsService } from '../../../services/user-exercise-sessions';
import { ExerciseSessionsService } from '../../../services/exercise-sessions';
import { ExercisesService } from '../../../services/exercises';
import { UsersTherapySessionsService } from '../../../services/users-therapy-sessions/users-therapy-sessions.service';
import { ConfigService } from '../../../../config/services';
import { ToolbarService } from '../../../../common/services/toolbar/toolbar.service';
import { addDays, addMonths, addWeeks, endOfDay, format, isBefore, startOfDay } from 'date-fns';
import { IonicColor } from '../../../../common/entities/toast/ionic-color';
import { TherapyTemplateListModalComponent } from '../../modal/therapy-template-list-modal/therapy-template-list-modal.component';
import { ModalConfig } from '../../../../common/entities/modal/modal-config';
import { ModalTyp } from '../../../../common/entities/modal/modal-typ';
import { ButtonConfig } from '../../../../common/entities/modal/modal-button';
import { RoutingSegment } from '../../../../common/entities/routing-segment';
import { TherapyTemplate } from '../../../entities/therapy/therapy-template';
import { TherapyDto } from '../../../entities/therapy';
import { Exercise } from '../../../entities/exercise';
import { ExerciseGoalDtoWrapper, PhysicalExerciseGoalDto } from '../../../entities/exerciseGoal/exerciseGoalDto';
import * as duration from 'duration-fns';

@Component({
    selector: 'lib-training-form',
    templateUrl: './training-form.component.html',
    styleUrls: ['./training-form.component.scss'],
})
export class TrainingFormComponent implements OnInit {
    @Input()
    training: TherapyExerciseGoal;
    @Input()
    isNew: boolean;
    @Input()
    user: User;
    @Input()
    showActionButton = true;
    @Output()
    isFormValid: EventEmitter<boolean> = new EventEmitter<boolean>();

    requiredValidators: Validators = Validators.compose([Validators.minLength(1), Validators.required]);
    numberValidators: Validators = Validators.compose([Validators.pattern(/^([1-9]|[1-9][0-9])$/)]);

    trainingForm: FormGroup;
    therapists: User[] = [];
    isEditEnabled = true;
    trainingTemplate: TherapyTemplate;
    protected readonly log: Logger;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private userService: UsersService,
        private formBuilder: FormBuilder,
        private usersExerciseGoalsService: UsersExerciseGoalsService,
        private toastService: ToastService,
        private therapiesService: TherapiesService,
        private usersTherapiesService: UsersTherapiesService,
        private modalCtrl: ModalController,
        private modalController: ModalController,
        private modalAlertService: ModalAlertService,
        private userExerciseSessionsService: UserExerciseSessionsService,
        private exerciseSessionsService: ExerciseSessionsService,
        private translate: TranslateService,
        private exercisesService: ExercisesService,
        private usersTherapySessionService: UsersTherapySessionsService,
        private configService: ConfigService,
        private toolbarService: ToolbarService,
        private loggingService: LoggingService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    async ngOnInit() {
        await this.initForm();
        this.toggleFormEdit();

        this.trainingForm.valueChanges.subscribe(() => {
            this.isFormValid.emit(this.trainingForm.valid);
        });
    }

    async selectTrainingTemplate() {
        if (this.trainingForm.controls.templateName.disabled) return;
        const trainingTemplate = this.trainingTemplate ? [this.trainingTemplate] : [];
        const modal = await this.modalCtrl.create({
            component: TherapyTemplateListModalComponent,
            cssClass: '',
            componentProps: {
                title: 'Trainingsplanvorlage wählen',
                searchString: 'Trainingsplanvorlage suchen',
                selectedTherapyTemplates: trainingTemplate,
                isMultipleChoice: false,
                anyItem: 'ANY_TRAINING_TEMPLATE',
                exerciseType: ExerciseType.TRAINING,
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data && data[0]) {
            this.trainingTemplate = data[0];
            this.trainingForm.controls.templateName.setValue(this.trainingTemplate.title);
            this.trainingForm.controls.title.setValue(this.trainingTemplate.title);
            this.trainingForm.controls.description.setValue(this.trainingTemplate.description);
            this.trainingForm.controls.therapySessionsPerWeek.setValue(this.trainingTemplate.therapySessionsPerWeek);
            this.trainingForm.controls.endDate.setValue(
                addWeeks(new Date(), duration.toWeeks(this.trainingTemplate.duration)).toISOString(),
            );
            if (this.trainingTemplate.therapyGoal)
                this.translate
                    .get(this.trainingTemplate.therapyGoal)
                    .subscribe((value) => this.trainingForm.controls.trainingGoal.setValue(value));
        }
    }

    async cancelFormEdit() {
        this.toggleFormEdit();
        this.training = (await this.usersTherapiesService.getUserTherapy(
            this.user.username,
            Number(this.route.snapshot.paramMap.get('trainingId')),
        )) as TherapyExerciseGoal;
        await this.initForm();
    }

    toggleFormEdit() {
        this.isEditEnabled = !this.isEditEnabled;
        const titleInput = this.trainingForm.get('title');
        const startDate = this.trainingForm.get('startDate');
        const endDate = this.trainingForm.get('endDate');
        const therapistsInput = this.trainingForm.get('therapists');
        const therapySessionsPerWeekInput = this.trainingForm.get('therapySessionsPerWeek');
        const descriptionInput = this.trainingForm.get('description');
        this.isEditEnabled ? titleInput.enable() : titleInput.disable();
        this.isEditEnabled ? startDate.enable() : startDate.disable();
        this.isEditEnabled ? endDate.enable() : endDate.disable();
        this.isEditEnabled ? therapistsInput.enable() : therapistsInput.disable();
        this.isEditEnabled ? therapySessionsPerWeekInput.enable() : therapySessionsPerWeekInput.disable();
        this.isEditEnabled ? descriptionInput.enable() : descriptionInput.disable();
    }

    async showDeletePrompt() {
        const modalConfig = new ModalConfig();
        modalConfig.modalTyp = ModalTyp.INFORMATION;
        modalConfig.title = `${this.training.title} löschen`;
        modalConfig.titleIcon = 'warning-outline';
        modalConfig.description = `Das Training wird unwiderruflich gelöscht.
        Wenn Sie den Training „${this.training.title}“ dennoch löschen möchten, klicken Sie auf „Löschen“.`;
        modalConfig.buttonLeft = new ButtonConfig();
        modalConfig.buttonLeft.buttonText = 'CANCEL';
        modalConfig.buttonLeft.buttonColor = 'primary';
        modalConfig.buttonRight = new ButtonConfig();
        modalConfig.buttonRight.buttonText = 'Löschen';
        modalConfig.buttonRight.buttonColor = 'danger';
        const action = await this.modalAlertService.showModal(modalConfig);
        if (action && action.action === 'right') {
            try {
                await this.usersTherapiesService.removeTherapyUser(this.user.username, this.training.id);
                await this.router.navigate(
                    [
                        RoutingSegment.MEMBER,
                        RoutingSegment.PATIENT_MANAGEMENT,
                        RoutingSegment.DETAIL,
                        this.user.username,
                    ],
                    {
                        replaceUrl: true,
                        queryParams: { segment: 'TRAINING' },
                    },
                );

                await this.toastService.showToast('Das Training wurde erfolgreich gelöscht.', IonicColor.success);
            } catch (e) {
                this.log.error(e);
                await this.toastService.showToast(
                    'Beim Löschen den Training ist ein Fehler aufgetreten.',
                    IonicColor.danger,
                );
            }
        }
    }

    setEndDate() {
        if (this.training.therapyTemplate.duration) {
            this.trainingForm.controls.endDate.setValue(
                addDays(
                    new Date(this.trainingForm.controls.startDate.value),
                    duration.toDays(this.training.therapyTemplate.duration),
                ).toISOString(),
            );
        } else {
            this.trainingForm.controls.endDate.setValue(this.trainingForm.controls.startDate.value);
        }
    }

    async checkEndDate() {
        if (
            isBefore(
                new Date(this.trainingForm.controls.endDate.value),
                new Date(this.trainingForm.controls.startDate.value),
            )
        ) {
            await this.toastService.showToast('Enddatum ist ungültig', IonicColor.danger);
            this.setEndDate();
        }
    }

    setTherapyDto() {
        const therapyDto = new TherapyDto();
        therapyDto.title = this.trainingForm.get('title').value;
        therapyDto.description = this.trainingForm.get('description').value;
        therapyDto.templateId = this.training.therapyTemplate.id;
        therapyDto.startDate = startOfDay(new Date(this.trainingForm.get('startDate').value));
        therapyDto.endDate = endOfDay(new Date(this.trainingForm.get('endDate').value));
        therapyDto.therapySessionsPerWeek = Number(this.trainingForm.get('therapySessionsPerWeek').value);
        therapyDto.therapists = this.therapists;
        therapyDto.exerciseType = ExerciseType.TRAINING;
        return therapyDto;
    }

    async setNewUserExerciseGoal(exerciseList: Exercise[]) {
        const userExerciseGoals = (await this.usersExerciseGoalsService.getUserExerciseGoals(
            this.user.username,
            true,
        )) as PhysicalExerciseGoalDto[];
        for (let exercise of exerciseList) {
            if (!this.training?.exercises.find((item) => item.id === exercise.id)) {
                try {
                    const exerciseResponse = await this.therapiesService.postExerciseOfTherapy(
                        this.training.id,
                        exercise.id,
                    );
                    exercise = await this.exercisesService.getExerciseById(exerciseResponse.exercise_id);
                    exercise.therapyExercise_id = exerciseResponse.id;
                    let exerciseGoal = userExerciseGoals.filter(
                        (e) => e.exerciseId === exerciseResponse.exercise_id,
                    )[0];
                    if (!exerciseGoal) {
                        exerciseGoal = exercise.exerciseGoals.filter((eg) => eg.active)[0] as PhysicalExerciseGoalDto;
                    }
                    exerciseGoal.exerciseId = exerciseResponse.exercise_id;
                    exerciseGoal.therapyId = this.training.id;
                    exercise.exerciseGoals.push(
                        await this.usersExerciseGoalsService.createUserExerciseGoal(
                            this.user.username,
                            new ExerciseGoalDtoWrapper(
                                PhysicalExerciseGoalDto.returnPhysicalExerciseGoalDtoToSend(
                                    exerciseGoal,
                                ) as PhysicalExerciseGoalDto,
                            ),
                        ),
                    );
                    this.training.exercises.push(exercise);
                } catch (e) {
                    this.log.error(e);
                    await this.toastService.showToast(e, IonicColor.danger);
                }
            }
        }
    }

    async saveTraining() {
        this.toggleFormEdit();
        try {
            if (this.isNew)
                this.training = (await this.therapiesService.createTherapyFromTemplateForPatient(
                    this.trainingTemplate.id,
                    this.user.username,
                    false,
                )) as TherapyExerciseGoal;
            await this.therapiesService.updateTherapy(this.training.id, this.setTherapyDto());
            await this.setNewUserExerciseGoal(this.training.exercises);

            await this.toastService.showToast(ToastService.changeSavedMessage, IonicColor.success);
            if (this.isNew)
                await this.router.navigate(
                    [
                        RoutingSegment.MEMBER,
                        RoutingSegment.PATIENT_MANAGEMENT,
                        RoutingSegment.TRAINING_MANAGEMENT,
                        RoutingSegment.DETAIL,
                        this.user.username,
                        this.training.id,
                    ],
                    { replaceUrl: true },
                );
        } catch (e) {
            this.log.error(e);
            await this.toastService.showToast(ToastService.errorMessageSave, IonicColor.danger);
        }
    }

    async initForm() {
        const templateName = this.training?.therapyTemplate?.title == null ? '' : this.training.therapyTemplate.title;
        let trainingGoal =
            this.training?.therapyTemplate?.therapyGoal == null ? '' : this.training?.therapyTemplate.therapyGoal;
        if (trainingGoal) this.translate.get(trainingGoal).subscribe((value) => (trainingGoal = value));
        const created_at = this.training?.created_at == null ? new Date() : this.training?.created_at;
        const tags = this.training?.tags == null ? '' : this.training.tags;
        const startDate = this.training?.startDate == null ? new Date().toISOString() : this.training.startDate;
        const endDate = this.training?.endDate == null ? addMonths(new Date(), 1).toISOString() : this.training.endDate;
        const title = this.training?.title == null ? '' : this.training.title;
        const therapists =
            this.training?.therapists == null
                ? ''
                : `${this.training?.therapists[0]?.firstname} ${this.training?.therapists[0]?.lastname}`;
        const description = this.training?.description == null ? '' : this.training.description;
        const therapySessionsPerWeek =
            this.training?.therapySessionsPerWeek == null ? '' : this.training.therapySessionsPerWeek;
        // const notes = this.training.notes == null ? '' : this.training.notes;

        this.trainingForm = this.formBuilder.group({
            title: new FormControl({ value: title, disabled: !this.isEditEnabled }, this.requiredValidators),
            therapists: new FormControl({ value: therapists, disabled: !this.isEditEnabled }),
            tags: new FormControl({ value: tags, disabled: !this.isEditEnabled }),
            created_at: new FormControl({
                value: format(new Date(created_at), 'dd.MM.yyyy'),
                disabled: true,
            }),
            startDate: new FormControl({
                value: startDate,
                disabled: !this.isEditEnabled,
            }),
            endDate: new FormControl({
                value: endDate,
                disabled: !this.isEditEnabled,
            }),
            templateName: new FormControl(
                {
                    value: templateName,
                    disabled: !this.isEditEnabled,
                },
                this.requiredValidators,
            ),
            trainingGoal: new FormControl({ value: trainingGoal, disabled: true }),
            therapySessionsPerWeek: new FormControl(
                {
                    value: therapySessionsPerWeek,
                    disabled: !this.isEditEnabled,
                },
                this.numberValidators,
            ),
            description: new FormControl({ value: description, disabled: !this.isEditEnabled }),
        });
        if (!this.isNew) this.trainingForm.controls.templateName.disable();
    }
}

import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { Router } from '@angular/router';
import { CurafidaSegmentItem } from '../../entities/curafida-segment.item';
import { IconColour, IconSizeTyp } from '../../entities/icon_type';

@Component({
    selector: 'curafida-tab-popover',
    templateUrl: './curafida-popover-tab.component.html',
    styleUrls: ['./curafida-popover-tab.component.scss'],
})
export class CurafidaPopoverTabComponent<T> implements OnInit {
    tabItem: CurafidaSegmentItem<T>[] = [];
    selectedSegment: T;
    IconColour = IconColour;
    IconSizeTyp = IconSizeTyp;

    constructor(private navParams: NavParams, public router: Router, private popoverController: PopoverController) {}

    ngOnInit():void {
        this.tabItem = this.navParams.get('tabItems');
    }

    async openPage(item: CurafidaSegmentItem<T>) :Promise<void>{
        await this.popoverController.dismiss(item.value);
    }

}

import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnChanges,
    OnInit,
    QueryList,
    SimpleChanges,
    ViewChildren,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map, throttleTime } from 'rxjs/operators';
import { EcgRingBuffer, Point2D } from '../ecg-ring-buffer';
import { Ecg3ChannelValues } from '../../../entities/bluetooth-models/online-data-structure';
import { SCREEN_STANDARD_SIZE, StyleService } from '../../../../common/services/style/style.service';
import { Logger, LoggingService } from '../../../../logging/logging.service';
import { UntilDestroy } from '@ngneat/until-destroy';

class CanvasProperties {
    height: number;
    width: number;
    id: string;
}

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'lib-ecg-3-channels-chart',
    templateUrl: './ecg-3-channels-chart.component.html',
    styleUrls: ['./ecg-3-channels-chart.component.scss'],
})
export class Ecg3ChannelsChartComponent implements AfterViewInit, OnChanges, OnInit {
    // For example: X-Values range from 0px and 300px, after 300px the chart is finished by a line with this width
    readonly gridLineWidth = 1;
    canvasWidth = 2 * 320 + this.gridLineWidth;
    readonly canvasHeight = 120 + this.gridLineWidth;
    // the gridLineWidth is needed to complete the graph.
    canvasListHtmlElement: CanvasProperties[] = [];
    contexts: CanvasRenderingContext2D[] = [];
    @Input() channel$: Observable<Ecg3ChannelValues>;
    @ViewChildren('canvas', { read: ElementRef }) canvases: QueryList<ElementRef<HTMLCanvasElement>>;
    protected readonly log: Logger;
    private readonly BUFFERSIZE = 768;
    private readonly maxX = this.BUFFERSIZE;
    // 1 square means 10mv = 50px
    private readonly amplitudeY = 7500;
    // readonly canvasHeight = 90 + this.gridLineWidth;
    private xScaleFactor = this.canvasWidth / this.maxX;
    private readonly yScaleFactor = this.canvasHeight / this.amplitudeY;
    private amplitud = 0.1 * this.yScaleFactor; // 0,1 mV * 100px / 2mv -> bigGrid = 1mV
    // xScaleFactor = 325px / 1000 points
    private subscription: Subscription;
    private ecgRingBuffers: EcgRingBuffer[] = [];
    @Input()
    private moveWindowPlottingFlag = false;
    @Input()
    private numberChannels: number;
    private oldIdSelected = 'I';

    constructor(private ngZone: NgZone, private styleService: StyleService, private loggingService: LoggingService) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    ngOnInit(): void {
        // CANVAS element has its own definition/attributes for width and height that is why is difficult to make it responsive. Next lines
        // are an alternative to resize the canvas.
        if (this.styleService.width < SCREEN_STANDARD_SIZE.max_smartphone_portrait) {
            this.canvasWidth = Math.trunc(this.styleService.width / 40) * 40 - 80 + this.gridLineWidth;
            this.xScaleFactor = this.canvasWidth / this.maxX;
        }
        for (let i = 0; i < this.numberChannels; i++) {
            this.ecgRingBuffers.push(
                new EcgRingBuffer(this.BUFFERSIZE, this.xScaleFactor, this.canvasHeight, this.yScaleFactor),
            );
            const canvasProperties = new CanvasProperties();
            canvasProperties.height = this.canvasHeight;
            canvasProperties.width = this.canvasWidth;
            canvasProperties.id = (i + 1).toString();
            this.canvasListHtmlElement.push(canvasProperties);
        }
    }

    ngAfterViewInit(): void {
        this.canvases.forEach((canvas) => {
            this.contexts.push(canvas.nativeElement.getContext('2d'));
        });
        if (!this.channel$) {
            this.log.error(`Error in ngAfterViewInit: channel$ not set`);
        } else {
            const obs$ = this.channel$.pipe(
                // bufferCount(40),
                // delay(80),
                // mergeMap(x => x),
                map((ecg12Channels) => {
                    this.ecgRingBuffers[0].enqueue(ecg12Channels.i);

                    this.ecgRingBuffers[1].enqueue(ecg12Channels.ii);

                    this.ecgRingBuffers[2].enqueue(ecg12Channels.iii);
                }),
                throttleTime(100),
            );
            this.subscription = obs$.subscribe(() => {
                this.ngZone.runOutsideAngular(() => {
                    for (let i = 0; i < this.contexts.length; i++) {
                        this.updateCanvas(this.ecgRingBuffers[i], this.contexts[i]);
                    }
                });
            });
        }
    }

    setMinValuePlot(value, minValue) {
        if (value < minValue || value > minValue + this.amplitudeY) {
            return value;
        } else if (value > minValue + this.amplitudeY) {
            return value - this.amplitudeY;
        } else {
            return minValue;
        }
    }

    selectOption() {
        this.moveWindowPlottingFlag = !this.moveWindowPlottingFlag;
    }

    selectChart(event) {
        /*const target = event.target || event.srcElement || event.currentTarget;
        const id = target.id;
        if (id) {
            if (document.getElementById(id).style.border.includes(`5px solid`)) {
                document.getElementById(id).style.border = `0px solid `;
            } else {
                document.getElementById(id).style.border = `5px solid #062f6f`;
            }
            if (document.getElementById(this.oldIdSelected).style.border.includes(`5px solid`)) {
                document.getElementById(this.oldIdSelected).style.border = `0px solid `;
            }
            this.oldIdSelected = id;
        }*/
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.moveWindowPlottingFlag) {
            for (const ctx of this.contexts) {
                ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
                ctx.beginPath();
            }
        }
    }

    private updateCanvas(ecgRingBuffer: EcgRingBuffer, ctx: CanvasRenderingContext2D) {
        if (this.moveWindowPlottingFlag) {
            this.movingWindow(ecgRingBuffer, ctx);
            // this.movingWindow2(ecgRingBuffer.getAll(this.BUFFERSIZE), ctx);
        } else {
            this.redrawWindow(ecgRingBuffer, ctx);
        }
    }

    private redrawWindow(ecgRingBuffer: EcgRingBuffer, ctx: CanvasRenderingContext2D) {
        // TODO: Change Code to incorporate the transformation from value to chartPoint here
        let currentPoint = ecgRingBuffer.getCurrent();
        ctx.beginPath();
        ctx.lineCap = 'round';
        if (currentPoint.y !== null) {
            ctx.moveTo(currentPoint.x, currentPoint.y);
            while (ecgRingBuffer.hasNext()) {
                const nextPoint = ecgRingBuffer.getNext();
                if (currentPoint.x > nextPoint.x) {
                    ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
                    ctx.beginPath();
                    ctx.lineCap = 'round';
                } else {
                    ctx.lineTo(nextPoint.x, nextPoint.y);
                }
                currentPoint = nextPoint;
            }
            ctx.stroke();
            ctx.closePath();
        }
    }

    private movingWindow(ecgRingBuffer: EcgRingBuffer, ctx: CanvasRenderingContext2D) {
        const points = ecgRingBuffer.getAll(this.BUFFERSIZE);
        ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
        ctx.beginPath();
        ctx.lineCap = 'round';
        for (let i = 0; i < points.length - 1; i++) {
            const point = points[i];
            const nextPoint = points[i + 1];
            ctx.moveTo(point.x, point.y);
            ctx.lineTo(nextPoint.x, nextPoint.y);
        }
        ctx.stroke();
        ctx.closePath();
    }

    private movingWindow2(points: Point2D[], ctx: CanvasRenderingContext2D) {
        ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
        ctx.beginPath();
        ctx.lineCap = 'round';
        for (let i = 0; i < points.length - 1; i++) {
            const point = points[i];
            const nextPoint = points[i + 1];
            ctx.moveTo(point.x, point.y);
            ctx.lineTo(nextPoint.x, nextPoint.y);
        }
        ctx.stroke();
        ctx.closePath();
    }
}

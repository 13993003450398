<ion-list>
    <ion-buttons class="full-width" *ngFor="let item of tabItem"
                 (click)="openPage(item)">
        <ion-button class="full-width" [ngClass]='{"selected-item": selectedSegment === item.value}'>
            <curafida-app-icon [iconName]='item.icon' [iconColour]='IconColour.PRIMARY' [iconSizeType]='IconSizeTyp.popover'></curafida-app-icon>
            <h5 class="full-width flex-container-row" style="margin: 0!important; text-transform: initial;">
                &nbsp;{{item.name | translate}} <span *ngIf="item.badge > 0 && !item.disabled" >({{item.badge}})</span></h5>
        </ion-button>
    </ion-buttons>
</ion-list>

<ion-menu contentId="{{contentID}}" (ionWillOpen)="onMenuOpen()">
    <ion-content>
        <ion-menu-toggle auto-hide="false">
            <ion-item *ngIf="!(roles?.length > 0)" class="pointer" (click)="login()">
                <ion-label class="menu-item" style="font-weight: bold" color="primary">
                    {{'LOGIN' | translate}}
                </ion-label>
            </ion-item>
        </ion-menu-toggle>
        <span *ngFor="let pageConfig of configService.config?.pageConfigs | MenuItemsFilter: roles: configService.config.features"
              (click)="openPage(pageConfig.url)">
        <ion-menu-toggle auto-hide="false">
            <ion-item *ngIf="pageConfig?.configuration?.menuVisibility && pageConfig.url"
                      [routerLink]="pageConfig.url"
                      class="pointer">
                <ion-label
                    [ngClass]="{'menu-item-active' : (menuItemActive(pageConfig.categories)),
                                    'menu-item' : (!menuItemActive( pageConfig.categories))}">
                    {{pageConfig.title | translate}}
                </ion-label>
            </ion-item>
        </ion-menu-toggle>
        <ion-item *ngIf="pageConfig?.configuration?.menuVisibility && !pageConfig.url" class="pointer"
                  (click)="pageConfig.configuration.showSubPagesDetailList = !pageConfig.configuration.showSubPagesDetailList">
            <ion-label class="menu-item">
                {{pageConfig.title | translate}}
            </ion-label>
            <ion-note slot="end" color="primary" style="font-size: x-large;">
                <ion-icon *ngIf="!pageConfig.configuration.showSubPagesDetailList"
                          name="chevron-down"></ion-icon>
                <ion-icon *ngIf="pageConfig.configuration.showSubPagesDetailList" name="chevron-up"></ion-icon>
            </ion-note>
        </ion-item>

        <ion-menu-toggle auto-hide="false">
            <ion-item
                [hidden]="!(subPageConfig.configuration.menuVisibility && subPageConfig.url && pageConfig.configuration.showSubPagesDetailList && pageConfig.childPageConfigs.length !==0)"
                *ngFor="let subPageConfig of pageConfig.childPageConfigs| MenuItemsFilter: roles: configService.config.features"
                [routerLink]="subPageConfig.url"
                class="pointer">
                <ion-label
                    [ngClass]="{
                                    'menu-sub-item-active' : (router.url.includes(subPageConfig.url)),
                                    'menu-sub-item' : (!router.url.includes( subPageConfig.url))
                             }">
                    {{subPageConfig.title | translate}}
                </ion-label>
            </ion-item>
        </ion-menu-toggle>
    </span>
        <ion-menu-toggle auto-hide="false">
            <ion-item *ngIf="roles && roles.length !== 0" class="pointer" (click)="logout()">
                <ion-label class="menu-item" style="font-weight: bold" color="primary">
                    {{ 'LOGOUT' | translate}}
                </ion-label>
            </ion-item>
        </ion-menu-toggle>
    </ion-content>
    <ion-footer>
        <ion-menu-toggle>
            <ion-item class="pointer" (click)="openUserManual()">
                <ion-label>
                    {{'USER.MANUAL' | translate}}
                </ion-label>
            </ion-item>
        </ion-menu-toggle>
        <span *ngFor="let pageConfig of configService.config.generalPageConfigs | checkFeaturePipe: configService.config.features"
              (click)="openPage(pageConfig.url)">
        <ion-menu-toggle auto-hide="false">
            <ion-item *ngIf="pageConfig?.configuration?.menuVisibility && pageConfig.url"
                      [routerLink]="pageConfig.url"
                      class="pointer">
                <ion-label
                    [ngClass]="{'menu-item-active' : (menuItemActive(pageConfig.categories)),
                                'menu-item' : (!menuItemActive(pageConfig.categories))}">
                    {{pageConfig.title | translate}}

                </ion-label>
            </ion-item>
        </ion-menu-toggle>
        <ion-item *ngIf="pageConfig?.configuration?.menuVisibility && !pageConfig.url" class="pointer"
                  (click)="pageConfig.configuration.showSubPagesDetailList = !pageConfig.configuration.showSubPagesDetailList">
            <ion-label  class="menu-item">
                {{pageConfig.title | translate}}
            </ion-label>
            <ion-note slot="end" color="primary" style="font-size: x-large;">
                <ion-icon *ngIf="!pageConfig.configuration.showSubPagesDetailList"
                          name="chevron-down"></ion-icon>
                <ion-icon *ngIf="pageConfig.configuration.showSubPagesDetailList" name="chevron-up"></ion-icon>
            </ion-note>
        </ion-item>
        <ion-menu-toggle auto-hide="false">
            <ion-item
                [hidden]="!(subPageConfig.configuration.menuVisibility && subPageConfig.url && pageConfig.configuration.showSubPagesDetailList && pageConfig.childPageConfigs.length !==0)"
                *ngFor="let subPageConfig of pageConfig.childPageConfigs| checkFeaturePipe: configService.config.features"
                [routerLink]="subPageConfig.url"
                class="pointer">
                <ion-label
                    [ngClass]="{
                                    'menu-sub-item-active' : (router.url.includes(subPageConfig.categories)),
                                    'menu-sub-item' : (!router.url.includes( subPageConfig.categories))
                             }">
                    {{subPageConfig.title | translate}}
                </ion-label>
            </ion-item>
        </ion-menu-toggle>
    </span>
        <ion-item *ngIf="isDebuggingAttributeEnabled">
            <ion-label>Logging</ion-label>
            <ion-toggle (ionChange)="switchLogging($event)"></ion-toggle>
        </ion-item>
        <ion-item lines="none" *ngIf="versionString">
            <ion-label side="right">{{versionString}}</ion-label>
        </ion-item>
        <ion-item *ngIf="appConfig && appConfig.developmentStage && appConfig.developmentStage === BuildType.dev" color="dev">
            <ion-label>Dev</ion-label>
        </ion-item>
        <ion-item *ngIf=" appConfig && appConfig.developmentStage && appConfig.developmentStage === BuildType.beta" color="beta">
            <ion-label>Beta</ion-label>
        </ion-item>
        <ion-item *ngIf=" appConfig && appConfig.developmentStage && appConfig.developmentStage === BuildType.demo" color="demo">
            <ion-label>Demo</ion-label>
        </ion-item>
    </ion-footer>
</ion-menu>

<ion-header>
    <curafida-toolbar-modal [title]="title"></curafida-toolbar-modal>
</ion-header>
<ion-content class="full-width">
    <div *ngIf="isComponentInitialised; else loading" class="flex-container-row ion-padding flex-wrap">
        <div
            *ngFor="let tagCategory of tags"
            [ngClass]="{ 'mobile-tag-column': isMobile, 'filter-column': !isMobile }"
            class="flex-container-column filter-column"
        >
            <div class="header">
                {{ tagCategory.label }}
            </div>
            <div *ngFor="let tag of tagCategory.tags" class="flex-container-row lines pointer" (click)="toggle(tag)">
                <ion-checkbox [checked]="isSelected(tag)"></ion-checkbox>
                <div class="flex-container-row justify-content-flex-start label">{{ tag.label }}</div>
            </div>
        </div>
        <div *ngIf="tags.length === 0">Es liegen keine Tags vor.</div>
    </div>
</ion-content>
<ng-template #loading>
    <curafida-loading></curafida-loading>
</ng-template>

<ion-footer class="no-border ion-margin-top ion-margin-bottom">
    <div
        [ngClass]="{ 'flex-container-column': isMobile, 'flex-container-row': !isMobile }"
        class="full-width justify-content-space-between ion-padding-end ion-padding-start"
    >
        <ion-button
            (click)="removeFilter()"
            class="btn-modal"
            color="danger"
            [disabled]="selectedItems.length === 0"
            attr.aria-label="{{ 'TAG.REMOVE_FILTER' | translate }}"
            >{{ 'TAG.REMOVE_FILTER' | translate }}</ion-button
        >
        <div class="flex-container-row justify-content-flex-end">
            <ion-button
                (click)="dismissModal()"
                class="btn-modal"
                color="danger"
                attr.aria-label="{{ 'CANCEL' | translate }}"
                >{{ 'CANCEL' | translate }}</ion-button
            >
            <ion-button (click)="saveModal()" class="btn-modal" attr.aria-label="{{ 'SAVE' | translate }}">
                {{ 'SAVE' | translate }}</ion-button>
        </div>
    </div>
</ion-footer>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
    AlternativeExerciseSession,
    ExerciseSessionCalendarEvent,
    ExerciseSessionDto,
    ExerciseSessionOfUser,
    ExerciseSessionOfUserSortProperties,
    ExerciseSessionState,
    ExerciseType,
    ViteroSessionCode,
} from '../../entities/exerciseSession';
import { isUndefined } from 'lodash-es';
import { FeedBack, FeedBackDto } from '../../entities/feedback';
import { ExerciseSessionUserStateDto } from '../../entities/exerciseSession/exercise-session-user-state';
import { ApiService } from '../../../api';
import { Exercise } from '../../entities/exercise';
import { PaginatedResponse, SortOrder } from '../../../common/entities/paginated-response';
import { ContentDto } from '../../entities/content';
import { ExerciseSessionUserResult } from '../../entities/exerciseSession/exercise-session-user-result';
import { BehaviorSubject, forkJoin, Subject } from 'rxjs';
import { ExerciseSessionAppointment } from '../../entities/appointement';
import { AuthorizationPipe, NoAuthorizationPipe } from '../../../hateoas/authorization.pipe';
import { TaskActionLinkName, TaskPreviewResource, TaskResource } from '../../components/task/task.resource';
import { HypermediaResource } from '../../../hateoas/hateoas.model';
import { TranslateService } from '@ngx-translate/core';
import { TasksSearchParameter } from '../../components/task/task.dto';
import { mergeMap } from 'rxjs/operators';

export interface GetExerciseSessionsOfUserPaginatedArguments {
    exerciseSessionStates?: ExerciseSessionState[];
    exerciseSessionUserStates?: ExerciseSessionState[];
    exerciseType?: ExerciseType;
    therapyId?: number;
    responsible?: string;
    responsibleUserRole?: string;
    sortBy?: ExerciseSessionOfUserSortProperties;
    sortOrder?: SortOrder;
    limit?: number;
    offset?: number;
    includeExerciseSessionStateChanges?: boolean;
    startTimeBefore?: string;
    startTimeAfter?: string;
    delayedTimeBefore?: string;
    delayedTimeAfter?: string;
    endTimeBefore?: string;
    endTimeAfter?: string;
    userFinishedBefore?: string;
    userFinishedAfter?: string;
    calendarEventRangeOverlapEnd?: string;
    calendarEventRangeOverlapStart?: string;
    appointmentFilterRangeOverlapEnd?: string;
    appointmentFilterRangeOverlapStart?: string;
    hasAppointment?: boolean;
    filter?: string;
    tagUuids?: string[];
}

@Injectable({
    providedIn: 'root',
})
export class UserExerciseSessionsService {
    readonly patientTasks$ = new Subject<TaskResource[]>();

    constructor(
        protected http: HttpClient,
        private authorizationPipe: AuthorizationPipe,
        private readonly noAuthorizationPipe: NoAuthorizationPipe,
        private readonly translateService: TranslateService,
    ) {}

    initPatientTasks(username: string, startOfInterval: string, endOfInterval: string, offset = 0, limit = 60): void {
        const taskUserStates = [
            ExerciseSessionState.PLANNED,
            ExerciseSessionState.ACTIVE,
            ExerciseSessionState.PATIENT_CANCELLED,
            ExerciseSessionState.FINISHED,
        ];
        const url = new URL(`${ApiService.url}tasks`);
        url.searchParams.set('offset', offset.toString());
        url.searchParams.set('limit', limit.toString());
        for (const state of taskUserStates) {
            url.searchParams.append('exerciseSessionUserStates', state);
        }
        url.searchParams.set('startTime', startOfInterval);
        url.searchParams.set('endTime', endOfInterval);
        url.searchParams.set('concernedUsername', username);
        this.http
            .get<PaginatedResponse<TaskPreviewResource[]>>(url.toString(), ApiService.options)
            .pipe(
                mergeMap((it) => {
                    return forkJoin([
                        ...it.items.map((task: TaskPreviewResource) => {
                            const url = new URL(`${ApiService.url}tasks/${task.id}`);
                            return this.http.get<TaskResource>(url.toString(), ApiService.options);
                        }),
                    ]).pipe();
                }),
            )
            .subscribe((it) => {
                this.patientTasks$.next(it);
            });
    }

    async getExerciseSessionsOfUserPaginated(
        username: string,
        args?: GetExerciseSessionsOfUserPaginatedArguments,
    ): Promise<PaginatedResponse<ExerciseSessionOfUser[]>> {
        const url = new URL(`${ApiService.url}users/${username}/exerciseSessionsOfUser`);

        if (args.offset) {
            url.searchParams.set('offset', args.offset.toString());
        } else {
            url.searchParams.set('offset', '0');
        }
        if (args.limit) {
            url.searchParams.set('limit', args.limit.toString());
        } else {
            url.searchParams.set('limit', '10');
        }
        if (args.sortBy) url.searchParams.set('sortBy', args.sortBy);
        if (args.sortOrder) url.searchParams.set('sortOrder', args.sortOrder);

        if (args.exerciseSessionStates) {
            for (const state of args.exerciseSessionStates) {
                {
                    url.searchParams.append('exerciseSessionStates', state);
                }
            }
        }
        if (args.exerciseSessionUserStates) {
            for (const state of args.exerciseSessionUserStates) {
                {
                    url.searchParams.append('exerciseSessionUserStates', state);
                }
            }
        }
        if (args.therapyId) url.searchParams.set('therapyId', args.therapyId.toString());
        if (args.exerciseType) url.searchParams.set('exerciseType', args.exerciseType);
        if (args.responsible) url.searchParams.set('responsible', args.responsible);
        if (args.responsibleUserRole) url.searchParams.set('responsibleUserRole', args.responsibleUserRole);
        if (!isUndefined(args.hasAppointment)) {
            url.searchParams.set('appointmentFilterProperties[hasAppointment]', JSON.stringify(args.hasAppointment));
        }
        if (args.includeExerciseSessionStateChanges) {
            url.searchParams.set(
                'includeExerciseSessionStateChanges',
                args.includeExerciseSessionStateChanges.toString(),
            );
        }
        if (args.startTimeBefore) {
            url.searchParams.set('appointmentFilterProperties[startTimeBefore]', args.startTimeBefore);
        }
        if (args.startTimeAfter) {
            url.searchParams.set('appointmentFilterProperties[startTimeAfter]', args.startTimeAfter);
        }
        if (args.delayedTimeBefore) {
            url.searchParams.set('appointmentFilterProperties[delayedTimeBefore]', args.delayedTimeBefore);
        }
        if (args.delayedTimeAfter) {
            url.searchParams.set('appointmentFilterProperties[delayedTimeAfter]', args.delayedTimeAfter);
        }
        if (args.endTimeBefore) url.searchParams.set('appointmentFilterProperties[endTimeBefore]', args.endTimeBefore);
        if (args.endTimeAfter) url.searchParams.set('appointmentFilterProperties[endTimeAfter]', args.endTimeAfter);
        if (args.userFinishedBefore) url.searchParams.set('userFinishedBefore', args.userFinishedBefore);
        if (args.userFinishedAfter) url.searchParams.set('userFinishedAfter', args.userFinishedAfter);
        if (args.calendarEventRangeOverlapStart) {
            url.searchParams.set('calendarEventRangeOverlap[start]', args.calendarEventRangeOverlapStart);
        }
        if (args.calendarEventRangeOverlapEnd) {
            url.searchParams.set('calendarEventRangeOverlap[end]', args.calendarEventRangeOverlapEnd);
        }
        if (args.appointmentFilterRangeOverlapStart) {
            url.searchParams.set(
                'appointmentFilterProperties[overlapRangeStart]',
                args.appointmentFilterRangeOverlapStart,
            );
        }
        if (args.appointmentFilterRangeOverlapEnd) {
            url.searchParams.set('appointmentFilterProperties[overlapRangeEnd]', args.appointmentFilterRangeOverlapEnd);
        }
        if (args.filter) url.searchParams.set('filter', args.filter);
        if (args?.tagUuids?.length > 0) {
            for (const tagUuid of args?.tagUuids) {
                url.searchParams.append('tagUuids', tagUuid);
            }
        }
        return await this.http
            .get<PaginatedResponse<ExerciseSessionOfUser[]>>(url.toString(), ApiService.options)
            .toPromise();
    }

    async fetchTasks(
        username?: string,
        args?: TasksSearchParameter,
    ): Promise<PaginatedResponse<TaskPreviewResource[]>> {
        const url = new URL(`${ApiService.url}tasks`);
        if (username) url.searchParams.set('concernedUsername', username);

        if (args.offset) {
            url.searchParams.set('offset', args.offset.toString());
        } else {
            url.searchParams.set('offset', '0');
        }
        if (args.limit) {
            url.searchParams.set('limit', args.limit.toString());
        } else {
            url.searchParams.set('limit', '10');
        }
        if (args.sortBy) url.searchParams.set('sortBy', args.sortBy);
        if (args.sortOrder) url.searchParams.set('sortOrder', args.sortOrder);

        if (args.exerciseSessionStates) {
            for (const state of args.exerciseSessionStates) {
                {
                    url.searchParams.append('exerciseSessionStates', state);
                }
            }
        }
        if (args.exerciseSessionUserStates) {
            for (const state of args.exerciseSessionUserStates) {
                {
                    url.searchParams.append('exerciseSessionUserStates', state);
                }
            }
        }
        if (args.responsible) url.searchParams.set('responsible', args.responsible);
        if (args.textSearchTerm) url.searchParams.set('textSearchTerm', args.textSearchTerm);
        if (args?.tagUuids?.length > 0) {
            for (const tagUuid of args?.tagUuids) {
                url.searchParams.append('tagUuids', tagUuid);
            }
        }
        return await this.http
            .get<PaginatedResponse<TaskPreviewResource[]>>(url.toString(), ApiService.options)
            .toPromise();
    }

    async fetchTask(taskId: number): Promise<TaskResource> {
        const url = new URL(`${ApiService.url}tasks/${taskId}`);
        return this.http.get<TaskResource>(url.toString(), ApiService.options).toPromise();
    }

    async getExerciseSessionOfUser(
        username: string,
        exerciseSessionId: number,
        includeExerciseSessionStateChanges?: boolean,
    ): Promise<ExerciseSessionOfUser> {
        const url = new URL(`${ApiService.url}users/${username}/exerciseSessions/${exerciseSessionId}`);
        if (includeExerciseSessionStateChanges) {
            url.searchParams.set('includeExerciseSessionStateChanges', includeExerciseSessionStateChanges.toString());
        }
        return this.http.get<ExerciseSessionOfUser>(url.toString(), ApiService.options).toPromise();
    }

    async getExerciseOfUserExerciseSession(username: string, exerciseSessionId: number): Promise<Exercise> {
        const url = `${ApiService.url}users/${username}/exerciseSessions/${exerciseSessionId}/exercise`;
        return this.http.get<Exercise>(url, ApiService.options).toPromise();
    }

    async createTask(username: string, exerciseSessionDto: ExerciseSessionDto): Promise<TaskResource> {
        const url = `${ApiService.url}tasks`;
        return this.http.post<TaskResource>(url, exerciseSessionDto, ApiService.options).toPromise();
    }

    postTaskAction(
        taskResource: HypermediaResource,
        taskAction: TaskActionLinkName,
    ): Promise<ExerciseSessionAppointment> {
        if (this.noAuthorizationPipe.transform(taskResource, taskAction, 'write')) {
            throw new Error(this.translateService.instant('FORBIDDEN'));
        }
        const url = new URL(taskResource._links[taskAction].href, ApiService.url);
        return this.http.post<ExerciseSessionAppointment>(url.toString(), '', ApiService.options).toPromise();
    }

    async postUsersExerciseSession(username: string, exerciseSessionDto: ExerciseSessionDto): Promise<number> {
        const url = `${ApiService.url}users/${username}/exerciseSessions`;
        return this.http.post<number>(url, exerciseSessionDto, ApiService.options).toPromise();
    }

    async postUsersExerciseSessionFinish(username: string, exerciseSessionId: number): Promise<void> {
        const url = `${ApiService.url}users/${username}/exerciseSessions/${exerciseSessionId}/finish`;
        return this.http.post<void>(url, '', ApiService.options).toPromise();
    }

    async postUsersExerciseSessionActivate(username: string, exerciseSessionId: number): Promise<void> {
        const url = `${ApiService.url}users/${username}/exerciseSessions/${exerciseSessionId}/activate`;
        return this.http.post<void>(url, '', ApiService.options).toPromise();
    }

    async postUsersExerciseSessionFeedback(
        username: string,
        exerciseSessionId: number,
        feedbackDto: FeedBackDto,
    ): Promise<FeedBack> {
        const url = `${ApiService.url}users/${username}/exerciseSessions/${exerciseSessionId}/feedbacks`;
        return this.http.post<FeedBack>(url, feedbackDto, ApiService.options).toPromise();
    }

    async getExerciseSessionAlternativesWithRestrictions(
        username: string,
        exerciseSessionId: number,
    ): Promise<PaginatedResponse<AlternativeExerciseSession[]>> {
        const url = `${ApiService.url}users/${username}/exerciseSessions/${exerciseSessionId}/alternativesWithRestrictions`;
        return this.http.get<PaginatedResponse<AlternativeExerciseSession[]>>(url, ApiService.options).toPromise();
    }

    async getExerciseSessionAlternatives(
        username: string,
        exerciseSessionId: number,
        ignoreGroupSize?: boolean,
        ignoreAlternativesDefinition?: boolean,
    ): Promise<PaginatedResponse<AlternativeExerciseSession[]>> {
        const url = new URL(`${ApiService.url}users/${username}/exerciseSessions/${exerciseSessionId}/alternatives`);
        if (ignoreGroupSize) url.searchParams.set('ignoreGroupSize', ignoreGroupSize.toString());
        if (ignoreAlternativesDefinition) {
            url.searchParams.set('ignoreAlternativesDefinition', ignoreAlternativesDefinition.toString());
        }
        return this.http
            .get<PaginatedResponse<AlternativeExerciseSession[]>>(url.toString(), ApiService.options)
            .toPromise();
    }

    async createAlternativeAppointmentWithRestriction(
        username: string,
        exerciseSessionId: number,
        alternativeExerciseSessionId: number,
    ): Promise<void> {
        const url = `${ApiService.url}users/${username}/exerciseSessions/${exerciseSessionId}/replaceByWithRestrictions/${alternativeExerciseSessionId}`;
        return this.http.post<void>(url, null, ApiService.options).toPromise();
    }

    async createAlternativeAppointment(
        username: string,
        exerciseSessionId: number,
        alternativeExerciseSessionId: number,
        ignoreGroupSize?: boolean,
        ignoreAlternativesDefinition?: boolean,
    ): Promise<void> {
        let url = `${ApiService.url}users/${username}/exerciseSessions/${exerciseSessionId}/replaceBy/${alternativeExerciseSessionId}`;
        let queryParams = '';
        if (ignoreGroupSize) queryParams += `ignoreGroupSize=${ignoreGroupSize}&`;
        if (ignoreAlternativesDefinition) {
            queryParams += `ignoreAlternativesDefinition=${ignoreAlternativesDefinition}&`;
        }

        if (queryParams.length > 0) {
            // ... remove the last char '&' and append the query param string to the url
            queryParams = queryParams.substring(0, queryParams.length - 1);
            url = url + '?' + queryParams;
        }
        return this.http.post<void>(url, null, ApiService.options).toPromise();
    }

    async getViteroSessionCode(username: string, exerciseSessionId: number): Promise<ViteroSessionCode> {
        const url = `${ApiService.url}users/${username}/exerciseSessions/${exerciseSessionId}/viteroSessionCode`;
        return this.http.get<ViteroSessionCode>(url, ApiService.options).toPromise();
    }

    async updateExerciseSessionUserState(
        username: string,
        exerciseSessionId: number,
        exerciseSessionUserStateDto: ExerciseSessionUserStateDto,
    ): Promise<ExerciseSessionOfUser> {
        return this.http
            .put<ExerciseSessionOfUser>(
                `${ApiService.url}users/${username}/exerciseSessions/${exerciseSessionId}/exerciseSessionUserState`,
                exerciseSessionUserStateDto,
                ApiService.options,
            )
            .toPromise();
    }

    async postExerciseSessionUserCancel(username: string, exerciseSessionId: number): Promise<void> {
        const url = `${ApiService.url}users/${username}/exerciseSessions/${exerciseSessionId}/cancel`;
        return this.http.post<void>(url, null, ApiService.options).toPromise();
    }

    async postExerciseSessionUserPatientCancel(username: string, exerciseSessionId: number): Promise<void> {
        const url = `${ApiService.url}users/${username}/exerciseSessions/${exerciseSessionId}/patient-cancel`;
        return this.http.post<void>(url, null, ApiService.options).toPromise();
    }

    async createExerciseSessionUserResult(
        username: string,
        exerciseSessionId: number,
        contentDto: ContentDto,
    ): Promise<ExerciseSessionUserResult> {
        const url = `${ApiService.url}users/${username}/exerciseSessions/${exerciseSessionId}/exerciseSessionUserResults`;
        return this.http.post<ExerciseSessionUserResult>(url, contentDto, ApiService.options).toPromise();
    }

    async updateExerciseSessionUserResult(
        username: string,
        exerciseSessionId: number,
        contentUuid: string,
        contentDto: ContentDto,
    ): Promise<ExerciseSessionUserResult> {
        const url = `${ApiService.url}users/${username}/exerciseSessions/${exerciseSessionId}/exerciseSessionUserResults/${contentUuid}`;
        return this.http.put<ExerciseSessionUserResult>(url, contentDto, ApiService.options).toPromise();
    }

    async deleteExerciseSessionUserResult(
        username: string,
        exerciseSessionId: number,
        contentUuid: string,
    ): Promise<void> {
        const url = `${ApiService.url}users/${username}/exerciseSessions/${exerciseSessionId}/exerciseSessionUserResults/${contentUuid}`;
        return this.http.delete<void>(url, ApiService.options).toPromise();
    }

    async updateExerciseSessionCalendarEvent(
        username: string,
        exerciseSessionId: number,
        exerciseSessionCalendarEvent: ExerciseSessionCalendarEvent,
    ): Promise<void> {
        const url = `${ApiService.url}users/${username}/exerciseSessions/${exerciseSessionId}/calendarEvent`;
        return this.http.put<void>(url, exerciseSessionCalendarEvent, ApiService.options).toPromise();
    }
}

<ion-content>
    <div class='wrapper'>
        <lib-curafida-page-title [subtitle]='subtitle'
                                 [title]='title'>
        </lib-curafida-page-title>
        <curafida-card [title]="'Allgemeine Daten'"
                       class='large-margin-bottom'>
            <ion-card-content style='padding-top: 10px'>
                <lib-training-form #trainingFormComponent
                                   [isNew]='isNew'
                                   [showActionButton]='loggedInRoles.includes(UserRoles.MANAGE_THERAPY)'
                                   [training]='training'
                                   [user]='user'>
                </lib-training-form>
            </ion-card-content>
        </curafida-card>
        <curafida-card *ngIf='previousUserTherapySessions?.total > 0'
                       [title]="'Rückmeldung vom ' + (previousUserTherapySessions?.items[0].created_at  | date: 'dd.MM.yyy')"
                       class='large-margin-bottom'>
            <ion-card-content style='padding-top: 10px'>
                <lib-training-result-table
                    [therapySession]='previousUserTherapySessions.items[0]'>
                </lib-training-result-table>
            </ion-card-content>
        </curafida-card>
        <curafida-card (setActionButton)='addNewExercise()'
                       [actionButtons]='actionButtons'
                       [disabled]='isNew'
                       [title]="'TRAINING.REQUIREMENT_AND_EXERCISE'"
                       class='large-margin-bottom'>
            <ion-card-content *ngIf='training?.exercises && training?.exerciseDefinition'
                              style='padding-top: 10px'>
                <curafida-table (setActionOnItem)='setActionOnItem($event)'
                                *ngIf='exerciseDefinitionOfUserTable'
                                [listTableConfig]='exerciseDefinitionOfUserTable'>
                </curafida-table>
                <div *ngIf='exerciseDefinitionOfUserTable && loggedInRoles.includes(UserRoles.MANAGE_THERAPY)'
                     class='button-container'>
                    <ion-button (click)='cancelExerciseGoalsEdit()'
                                *ngIf='exerciseDefinitionOfUserTable && isEditContentEnabled'
                                class='btn-font-medium'
                                color='danger'>
                        {{'CANCEL' | translate}}
                    </ion-button>
                    <ion-button (click)='saveExerciseGoals()'
                                *ngIf='exerciseDefinitionOfUserTable && isEditContentEnabled'
                                class='btn-font-medium'>
                        {{'SAVE_CHANGES' | translate}}
                    </ion-button>
                    <ion-button (click)='toggleTable()'
                                *ngIf='exerciseDefinitionOfUserTable && !isEditContentEnabled'
                                class='btn-font-medium'>
                        {{'EDIT' | translate}}
                    </ion-button>
                </div>
            </ion-card-content>
        </curafida-card>
    </div>
</ion-content>

import {Injectable} from '@angular/core';
import {Router, UrlTree} from '@angular/router';
import {Logger, LoggingService, LogLevel} from '../logging/logging.service';
import {CurafidaAuthService} from '../auth/services';
import {SplashScreenStateService} from '../common/services/splash-screen-state.service';

@Injectable({
    providedIn: 'root',
})
export class LoginGuardService {
    protected readonly log: Logger;

    constructor(
        protected authService: CurafidaAuthService,
        private router: Router,
        private loggingService: LoggingService,
        private splashScreenStateService: SplashScreenStateService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
        this.log.setLevel(LogLevel.INFO);
    }

    async canActivate(): Promise<UrlTree | boolean> {
        // Check authenticated state without initializing keycloakjs
        const isAuthenticated = await this.authService.isAuthenticated(false, false);
        if (isAuthenticated) {
            this.log.debug(`[canActivate] User is already authenticated. Navigate to root /`);
            return this.router.parseUrl('');
        }
        this.log.info(`[canActivate] User is NOT authenticated. Stay on /login and return true.`);
        this.splashScreenStateService.stop();
        return true;
    }
}

<div
    *ngIf="formGroup && formControlName"
    class="flex-container-row full-width text-standard-input-size"
    [formGroup]="formGroup"
>
    <ion-label
        *ngIf="!isMobile"
        class="item-label-user-info self-align-center ion-padding-top word-breaking text-standard-input-size"
        [ngClass]="{
            'ion-label-color-disabled': formGroup.controls[formControlName].status === 'DISABLED',
            'ion-label-color-enabled': formGroup.controls[formControlName].status !== 'DISABLED'
        }"
        >{{ label }}</ion-label
    >
    <ion-label
        *ngIf="!isMobile"
        [ngClass]="{
            'ion-label-color-disabled': formGroup.controls[formControlName].status === 'DISABLED',
            'ion-label-color-enabled': formGroup.controls[formControlName].status !== 'DISABLED'
        }"
        >{{ explanation }}</ion-label
    >
    <ion-item
        class="pointer text-standard-input-size"
        [ngClass]="{
            'item-input-width ion-item-box': !isMobile,
            'item-input-full-width': isMobile,
            'ion-item-box-enable': formGroup.controls[formControlName].status !== 'DISABLED',
            'ion-item-box-disable': formGroup.disabled
        }"
    >
        <ion-label
            *ngIf="isMobile"
            position="floating"
            [ngClass]="{
                'ion-label-color-disabled': formGroup.controls[formControlName].status === 'DISABLED',
                'ion-label-color-enabled': formGroup.controls[formControlName].status !== 'DISABLED'
            }"
            >{{ label }}</ion-label
        >
        <ion-input formControlName="{{ formControlName }}" readonly='true'> </ion-input>
        <ion-label *ngIf="!isMobile"><ion-icon name="reorder-four-outline"></ion-icon></ion-label>
    </ion-item>
</div>
<div *ngIf='formGroup' [formGroup]='formGroup'>
    <error-message *ngIf="formGroup && formControlName && formErrors" [errorMessageInput]="formErrors" [formControlName]="formControlName"
                   [formGroup]="formGroup"></error-message>
</div>

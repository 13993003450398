import { Component, Input, OnInit } from '@angular/core';
import { ExerciseSessionOfUser } from '../../entities/exerciseSession';
import { ItemAdapterComponent, TableItem } from '../../../table/entities/table';
import { FeedbackFlag } from '../../entities/feedback/feedback-comment';
import { IntensityUnit, StressUnit } from '../../entities/exerciseGoal/exerciseGoalDto';

@Component({
    template: `
        <div *ngIf="data?.feedback" class="flex-container-column">
            <div class="flex-container-row">
                <div [hidden]="!data?.feedback?.exerciseParams" class="value-col">
                    <span *ngFor="let exerciseParam of data?.feedback?.exerciseParams; let i = index"
                        >{{ exerciseParam.duration }}
                        <span *ngIf="i < data?.feedback?.exerciseParams.length - 1">/ </span></span
                    >
                </div>
            </div>
            <div class="flex-container-row" *ngIf="hasPulse">
                <div
                    [hidden]="!data?.feedback?.exerciseParams"
                    class="value-col flex-container-row justify-content-flex-start"
                >
                    <span
                        [hidden]="!exerciseParam.pulse"
                        *ngFor="let exerciseParam of data?.feedback?.exerciseParams; let i = index"
                        >{{ exerciseParam.pulse }}
                        <span *ngIf="i < data?.feedback?.exerciseParams.length - 1">/ </span></span
                    >
                    <ion-icon
                        *ngIf="data.feedback.pulseFlag !== FeedbackFlag.NONE"
                        class="flag-icon"
                        [ngClass]="{
                            red: data.feedback.pulseFlag === FeedbackFlag.RED,
                            yellow: data.feedback.pulseFlag === FeedbackFlag.YELLOW,
                            green: data.feedback.pulseFlag === FeedbackFlag.GREEN
                        }"
                        name="flag"
                    ></ion-icon>
                </div>
            </div>
            <div class="flex-container-row" *ngIf="hasIntensity">
                <div [hidden]="!data?.feedback?.exerciseParams" class="value-col">
                    <span
                        [hidden]="!exerciseParam.intensity"
                        *ngFor="let exerciseParam of data?.feedback?.exerciseParams; let i = index"
                        >{{ exerciseParam.intensity }}
                        <span *ngIf="i < data?.feedback?.exerciseParams.length - 1">/ </span></span
                    >
                </div>
            </div>
        </div>
    `,
})
export class FeedbackAdapterComponent implements ItemAdapterComponent, OnInit {
    @Input() data: ExerciseSessionOfUser;
    @Input() itemSetting: TableItem;

    hasPulse = true;
    hasIntensity = true;
    disabled = false;
    FeedbackFlag = FeedbackFlag;

    constructor() {}

    ngOnInit(): void {
        this.hasIntensity = this.data.exerciseGoal.intensityUnit !== IntensityUnit.NONE;
        this.hasPulse = this.data.exerciseGoal.stressUnit !== StressUnit.NONE;
    }
}

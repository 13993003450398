import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonDatetime, NavParams, PopoverController } from '@ionic/angular';
import { addYears } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';

@Component({
    selector: 'app-date-time-picker',
    templateUrl: './date-time-picker.component.html',
    styleUrls: ['./date-time-picker.component.scss'],
})
export class DateTimePickerComponent implements OnInit {
    @ViewChild(IonDatetime, { static: true }) datetime: IonDatetime;

    @Input() title?: string;

    @Input()
    date: string | string[];

    @Input()
    disablePastDays = false;
    @Input()
    minDate?: string;

    @Input()
    disableFutureDays = false;
    @Input()
    maxDate?: string;

    @Input()
    minuteStep = 15;
    minuteValues = '0';

    @Input()
    presentation: 'date' | 'date-time' | 'month' | 'month-year' | 'time' | 'time-date' | 'year';
    patterns = [
        /(Z)$/, // Z
        /([+-])(\d{2})$/, // ±hh
        /([+-])(\d{2}):?(\d{2})$/, // ±hh:mm or ±hhmm
        /(UTC|(?:[a-zA-Z]+\/[a-zA-Z_]+(?:\/[a-zA-Z_]+)?))$/, // IANA time zone
    ];

    constructor(private popoverController: PopoverController, protected params: NavParams) {}

    ngOnInit(): void {
        if (this.params.get('minuteStep')) {
            this.minuteStep = this.params.get('minuteStep');
        }
        if (this.params.get('disabledPastDay')) {
            this.disablePastDays = this.params.get('disabledPastDay');
        }
        if (this.params.get('disableFutureDays')) {
            this.disableFutureDays = this.params.get('disableFutureDays');
        }
        if (this.disablePastDays) this.minDate = new Date().toISOString();
        if (this.disableFutureDays) this.maxDate = new Date().toISOString();

        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let zonedTime: string;
        if (this.params.get('date') != null) {
            zonedTime = utcToZonedTime(this.utcDate(new Date(this.params.get('date'))), userTimeZone).toISOString();
        } else {
            zonedTime = utcToZonedTime(new Date(), userTimeZone).toISOString();
        }
        if (this.date)
            this.date = format(new Date(zonedTime), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", { timeZone: userTimeZone });

        if (this.params.get('maxDate')) {
            this.maxDate = this.params.get('maxDate');
        }
        if (this.params.get('minDate')) {
            this.minDate = this.params.get('minDate');
        }
        // Set default maxDate to 10 years from now, if not set it defaults to the end of the year
        if (!this.maxDate) this.maxDate = addYears(new Date(), 10).toISOString();
        if (this.params.get('presentation')) {
            this.presentation = this.params.get('presentation');
        } else {
            this.presentation = 'date';
        }
        if (this.minuteStep) {
            this.minuteValues = '0';
            for (let i = this.minuteStep; i < 60; i = i + this.minuteStep) {
                this.minuteValues = this.minuteValues + ',' + i.toString();
            }
        }
    }

    async sendDate() {
        await this.popoverController.dismiss(this.date);
    }

    async dismissPopOver() {
        await this.popoverController.dismiss();
    }

    saveDate(value: string | string[]) {
        if (value) this.date = value;
    }

    utcDate(dateStr) {
        const ts = Date.parse(dateStr);
        if (!ts) {
            throw new Error('Invalid Date');
        }

        const date = new Date(ts);
        if (this.hasTimezone(dateStr)) {
            return new Date(date.getTime() + date.getTimezoneOffset() * 6e4);
        }
        return date;
    }

    /**
     * Returns true if it matches any of the time zone offset formats
     *
     * @param {string} timezoneString
     * @returns {boolean}
     */
    hasTimezone(timezoneString) {
        return this.patterns.some((pattern) => pattern.exec(timezoneString));
    }
}

<ion-list>
    <ion-buttons class="full-width" *ngFor="let page of subPages"
                 (click)="openPage(page.url)">
        <ion-button [hidden]="!page.url" class="full-width"  [id]="page.pageId" [ngClass]="{
                    'sub-page-active' : router.url.includes(page.url) && page.configuration.headerVisibility,
                    'sub-page' : !router.url.includes(page.url) && page.configuration.headerVisibility,
                    'sub-page-display-none' : !page.configuration.headerVisibility
                     }"> <span  class="full-width flex-container-row">{{page.title | translate}}</span>
        </ion-button>
    </ion-buttons>
</ion-list>

<div *ngIf='formGroup && formControlName'
     [formGroup]='formGroup'
     class='flex-container-row full-width text-standard-input-size'
>
    <ion-label
        *ngIf='!isMobile'
        [ngClass]="{ 'ion-label-color-disabled': ngControl.disabled, 'ion-label-color-enabled': !ngControl.disabled }"
        class='item-label-user-info self-align-center ion-padding-top word-breaking'
    >{{ label }}</ion-label
    >
    <ion-item
        class='text-standard-input-size'
        [disabled]='disabled'
        [lines]="'none'"
        [ngClass]="{
            'item-input-width ion-item-box': !isMobile,
            'item-input-full-width': isMobile,
            'ion-item-box-enable': !ngControl.disabled,
            'ion-item-box-disable': ngControl.disabled
        }"
    >
        <ion-label
            *ngIf='formGroup.controls[formControlName].value === null && allowInputSelection'
            style='--opacity: 1'
        >{{ 'PLEASE_SELECT' | translate }}</ion-label
        >
        <ion-label
            *ngIf='isMobile'
            [ngClass]="{
                'ion-label-color-disabled': ngControl.disabled,
                'ion-label-color-enabled': !ngControl.disabled
            }"
            position='floating'
        >{{ label }}
        </ion-label>
        <ion-select
            (ionChange)='onIonChange($event)'
            [cancelText]="'CANCEL' | translate"
            [ngClass]="{ 'opacity-full': ngControl.disabled }"
            [okText]="'SELECT' | translate"
            formControlName='{{ formControlName }}'
            interface='popover'
            style='max-width: 100% !important; padding-left: 0; padding-inline-start: 0'
        >
            <ion-select-option *ngFor='let item of itemList' [disabled]='disabled' [value]='item.value'>{{
                item.label | translate
                }}</ion-select-option>
        </ion-select>
        <ion-icon
            *ngIf='!ngControl.disabled'
            class='ion-align-self-center'
            name='caret-down-outline'
            slot='end'
        ></ion-icon>
    </ion-item>
</div>
<div *ngIf='formGroup' [formGroup]='formGroup'>
    <error-message *ngIf="formGroup && formControlName && formErrors" [errorMessageInput]="formErrors" [formControlName]="formControlName"
                   [formGroup]="formGroup"></error-message>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Logger, LoggingService } from '../../../logging/logging.service';
import { defaultInputValidator } from '../../validators/curafida-validators';
import { RangeTyp } from '../../../therapy/pages/training/training-patient-detail/training-patient-detail.page';
import Timeout = NodeJS.Timeout;

@Component({
    selector: 'lib-range-selector',
    templateUrl: './range-selector.component.html',
    styleUrls: ['./range-selector.component.scss'],
})
export class RangeSelectorComponent implements OnInit {
    @Input()
    rangeValue: RangeDefinition;
    @Input()
    isVertical = false;
    @Output()
    emitValue: EventEmitter<number> = new EventEmitter<number>();
    SelectorAction = SelectorAction;
    rangeSelectForm: FormGroup;
    pulseValidators: Validators = Validators.compose([
        defaultInputValidator,
        Validators.pattern('^(?:35|3[5-9]|[4-9][0-9]|1[0-9][0-9]|2[0-2][0-9]|230)$'),
    ]);
    seriesValidators: Validators = Validators.compose([
        defaultInputValidator,
        Validators.pattern('^(?:0|[0-9]|[1-9][0-9])$'),
    ]);
    pulseVarianceValidators: Validators = Validators.compose([
        defaultInputValidator,
        Validators.pattern('^(?:0|[0-9]|[1-9][0-9])$'),
    ]);
    durationValidators: Validators = Validators.compose([
        defaultInputValidator,
        Validators.pattern('^(?:0|[1-9]|[1-9][0-9]|[1-9][0-9][0-9]|[1-4][1-9][0-9][0-9]|5000)$'),
    ]);
    intensityValidators: Validators = Validators.compose([
        defaultInputValidator,
        Validators.pattern(
            '^(?:0(?:(\\.|\\,)[0-9])?|[0-9](?:(\\.|\\,)[0-9])?|[0-9][0-9](?:(\\.|\\,)[0-9])?|[0-9][0-9][0-9](?:(\\.|\\,)[0-9])?|[0-4][1-9][0-9][0-9](?:(\\.|\\,)[0-9])?|5000(?:(\\.|\\,)[0-9])?)',
        ),
    ]);
    buttonUp = document.getElementById('#buttonUp');
    buttonDown = document.getElementById('#buttonDown');
    protected readonly log: Logger;
    private interval: Timeout;

    constructor(private loggingService: LoggingService, private formBuilder: FormBuilder) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    ngOnInit(): void {
        if (this.rangeValue) {
            const rangeValue = this.rangeValue.value ? this.rangeValue.value : 0;
            if (this.rangeValue.type === RangeTyp.DURATION) {
                this.rangeSelectForm = this.formBuilder.group({
                    inputValue: new FormControl({ value: rangeValue, disabled: false }, this.durationValidators),
                });
            }
            if (this.rangeValue.type === RangeTyp.INTENSITY) {
                this.rangeSelectForm = this.formBuilder.group({
                    inputValue: new FormControl({ value: rangeValue, disabled: false }, this.intensityValidators),
                });
            }
            if (this.rangeValue.type === RangeTyp.PULSE) {
                this.rangeSelectForm = this.formBuilder.group({
                    inputValue: new FormControl({ value: rangeValue, disabled: false }, this.pulseValidators),
                });
            }
            if (this.rangeValue.type === RangeTyp.SERIES) {
                this.rangeSelectForm = this.formBuilder.group({
                    inputValue: new FormControl({ value: rangeValue, disabled: false }, this.seriesValidators),
                });
            }
            if (this.rangeValue.type === RangeTyp.PULSE_VARIANCE) {
                this.rangeSelectForm = this.formBuilder.group({
                    inputValue: new FormControl({ value: rangeValue, disabled: false }, this.pulseVarianceValidators),
                });
            }
        }
        if (this.buttonUp) this.buttonUp.addEventListener('mouseout', () => clearInterval(this.interval));

        if (this.buttonDown) this.buttonDown.addEventListener('mouseout', () => clearInterval(this.interval));
    }

    sendValue() {
        if (!this.rangeSelectForm.valid) this.rangeSelectForm.controls.inputValue.setValue(this.rangeValue.value);
        if (this.rangeSelectForm.controls.inputValue.value > this.rangeValue.max)
            this.rangeSelectForm.controls.inputValue.setValue(this.rangeValue.value);
        if (this.rangeSelectForm.controls.inputValue.value < this.rangeValue.min)
            this.rangeSelectForm.controls.inputValue.setValue(this.rangeValue.value);
        let value = this.rangeSelectForm.controls.inputValue.value.toString();
        value = value.replace(',', '.');
        this.emitValue.emit(Number(value));
    }

    setActionButton(action: SelectorAction) {
        if (this.rangeValue.type === RangeTyp.PULSE && Number(this.rangeValue.value) === 0) {
            this.rangeSelectForm.controls.inputValue.patchValue(this.rangeValue.min);
            this.rangeValue.value = 60;
        }

        if (!this.rangeValue.max || !this.rangeValue.min) {
            if (action === SelectorAction.LOWER && Number(this.rangeValue.value) > 0)
                this.rangeSelectForm.controls.inputValue.patchValue(
                    Number(this.rangeSelectForm.controls.inputValue.value) - Number(this.rangeValue.range),
                );
            if (action === SelectorAction.UPPER)
                this.rangeSelectForm.controls.inputValue.patchValue(
                    Number(this.rangeSelectForm.controls.inputValue.value) + Number(this.rangeValue.range),
                );
        } else {
            if (
                action === SelectorAction.LOWER &&
                Number(this.rangeSelectForm.controls.inputValue.value) > this.rangeValue.min
            ) {
                this.rangeSelectForm.controls.inputValue.patchValue(
                    Number(this.rangeSelectForm.controls.inputValue.value) - Number(this.rangeValue.range),
                );
            }

            if (
                action === SelectorAction.UPPER &&
                Number(this.rangeSelectForm.controls.inputValue.value) < this.rangeValue.max
            ) {
                this.rangeSelectForm.controls.inputValue.patchValue(
                    Number(this.rangeSelectForm.controls.inputValue.value) + Number(this.rangeValue.range),
                );
            }
        }
        this.sendValue();
    }

    pressEvent(action: SelectorAction) {
        this.pressUpEvent();
        this.interval = setInterval(() => {
            this.setActionButton(action);
        }, 100);
    }

    pressUpEvent() {
        clearInterval(this.interval);
    }

    clickEvent(action: SelectorAction) {
        this.pressUpEvent();
        this.setActionButton(action);
    }
}

export enum SelectorAction {
    LOWER = 'LOWER',
    UPPER = 'UPPER',
}

export class RangeDefinition {
    value: number;
    range = 1;
    min?: number;
    max?: number;
    unit?: string;
    disabled? = false;
    type?: RangeTyp;
}

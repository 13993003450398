import { Component, Input } from '@angular/core';
import { ItemAdapterComponent, TableItem } from '../../../table/entities/table';
import { ExerciseCatalog } from '../../entities/exercise';

@Component({
    template: `
        <ion-chip
            class="ion-no-margin"
            *ngFor="let tag of this.data[this.itemSetting.prop]"
            color="primary"
            outline="true"
            [disabled]="true"
            style="opacity: 1!important; margin-bottom: 4px; margin-right: 4px"
        >
            <ion-label>{{ tag.label }}</ion-label>
        </ion-chip>
    `,
})
export class TagsAdapterComponent implements ItemAdapterComponent {
    @Input() data: ExerciseCatalog;
    @Input() itemSetting: TableItem;
}

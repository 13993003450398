<div *ngIf='formGroup && formControlName' [formGroup]='formGroup'
     class='flex-container-row full-width text-standard-input-size'>
    <div class='item-label-user-info flex-container-row justify-content-space-between'  *ngIf='!isMobile'>
        <ion-label
                   [ngClass]="{'ion-label-color-disabled': formGroup.controls[formControlName].status === 'DISABLED', 'ion-label-color-enabled': formGroup.controls[formControlName].status !== 'DISABLED'}"
                   class=' self-align-start ion-padding-top word-breaking'
                   style='padding-top:calc(var(--padding-top) + 8px)'>{{label}}</ion-label>
        <ion-button *ngIf='hasExpandButton' class='no-vertical-margin' (click)='expandTextArea()'>
            <ion-icon name='expand-outline'></ion-icon>
        </ion-button>
    </div>

    <ion-item [ngClass]="{'item-input-width ion-item-box': !isMobile,
    'item-input-full-width': isMobile, 'ion-item-box-enable':formGroup.controls[formControlName].status !== 'DISABLED', 'ion-item-box-disable':formGroup.controls[formControlName].status === 'DISABLED'}"
              class='text-standard-input-size' lines='none'>
        <ion-label *ngIf='isMobile'
                   [ngClass]="{'ion-label-color-disabled': formGroup.controls[formControlName].status === 'DISABLED', 'ion-label-color-enabled': formGroup.controls[formControlName].status !== 'DISABLED'}"
                   position='floating'>{{label}}</ion-label>
        <ion-textarea [maxlength]='maxlength' autoGrow='true' autosize
                      formControlName='{{formControlName}}' rows='2' style='min-height: 100px; line-height: normal'
                      type='text'></ion-textarea>
    </ion-item>
</div>

<div *ngIf='formGroup && formControlName' [formGroup]='formGroup' class='flex-container-row full-width'>
    <ion-label *ngIf='!isMobile'
               class='item-label-user-info self-align-start ion-padding-top word-breaking'></ion-label>
    <p *ngIf='maxlength' [ngClass]="{'color-red': formGroup.controls[formControlName].value?.length > maxlength}"
       style='margin-top: 0'>{{formGroup.controls[formControlName].value?.length ? formGroup.controls[formControlName].value?.length : 0}}
        / {{maxlength}} Zeichen</p>
</div>

<div *ngIf='formGroup' [formGroup]='formGroup'>
    <error-message *ngIf='formGroup && formControlName && formErrors' [errorMessageInput]='formErrors'
                   [formControlName]='formControlName'
                   [formGroup]='formGroup'></error-message>
</div>
